import { Button, Flex, Modal, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { translate } from 'i18n/i18n'
import './LogoutDialog.scss'

const LogoutDialog = ({
  open,
  onClose,
  onLogout,
  title = translate('CommonUtils.AreYouSureYouWantToLogout')
}) => {
  /* i18n dependencies */
  const { t } = useTranslation()
  /* i18n dependencies */

  return (
    <Modal destroyOnClose centered footer={false} open={open} onCancel={onClose} width={500}>
      <Flex gap={'2rem'} vertical style={{ marginTop: 50 }}>
        <Typography.Title level={2} style={{ textAlign: 'center' }}>
          {title}
        </Typography.Title>
        <Flex justify="center" align="center" gap={'1rem'} wrap="wrap">
          <Button type="default" size="large" onClick={onClose}>
            {t('CommonUtils.Cancel')}
          </Button>
          <Button
            size="large"
            style={{ backgroundColor: 'var(--postal-red)', border: 'none' }}
            onClick={onLogout}
          >
            {t('CommonUtils.Logout')}
          </Button>
        </Flex>
      </Flex>
    </Modal>
  )
}

export default LogoutDialog
