import { createAsyncThunk } from '@reduxjs/toolkit'
import { AppRoutingConfig } from 'assets/config/AppRoutingConfig'
import axiosInstance from 'axiosInstance'
import { roles } from '../../constants'
import { displayMultipleNotifications } from 'utils/notificationsUtils'

// get community
export const get_community = createAsyncThunk(
  'community/get_community',
  async ({ payload, showNotification, userDetails }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/community/get`, payload)
      if (response.status === 0) {
        return { requestPayload: payload, response: response.data, userDetails }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// community create token
export const community_create_token = createAsyncThunk(
  'community/community_create_token',
  async ({ payload, navigate, navigateRoute, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/community/create-token`, payload)
      if (response.status === 0) {
        navigate(navigateRoute)
        ![roles.superAdmin].includes(response?.data?.role) &&
          showNotification({
            type: 'success',
            message: response?.message
          })
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// community revoke token
export const community_revoke_token = createAsyncThunk(
  'community/community_revoke_token',
  async ({ payload, showNotification, navigate }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/community/revoke-token`, payload)
      if (response.status === 0) {
        navigate(AppRoutingConfig.HOME)
        showNotification({
          type: 'success',
          message: response?.message
        })
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// upload profile
export const upload_profile = createAsyncThunk(
  'community/upload_profile',
  async ({ payload, showNotification, userDetails }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/profile/upload/profilepic`, payload)
      if (response.status === 0) {
        showNotification({
          type: 'success',
          message: response?.message
        })
        return { requestPayload: payload, response: response.data, userDetails }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// remove profile
export const remove_profile = createAsyncThunk(
  'common/remove_profile',
  async ({ payload, showNotification, userDetails }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/profile/remove/profilepic`, payload)
      if (response.status === 0) {
        showNotification({
          type: 'success',
          message: response?.message
        })
        return { requestPayload: payload, response: response.data, userDetails }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// get all user activity
export const get_user_activity = createAsyncThunk(
  'community/get_user_activity',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/admin/get-activity-list`, payload)
      if (response.status === 0) {
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// get signed urls
export const get_signed_url = createAsyncThunk(
  'common/get_signed_url',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/auth/get-signed-url`, payload)
      if (response.status === 0) {
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// get countries
export const get_countries = createAsyncThunk(
  'common/get_countries',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`v1/profile/search/countries`, {
        params: {
          search: payload?.search || '',
          page: payload?.page || '',
          pageSize: payload?.pageSize || ''
        }
      })
      if (response.status === 0) {
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)
