import { Flex, Modal, Typography } from 'antd'
import React from 'react'

const DynamicModal = ({ open, onClose, dynamicModaldata, dynamicModalIndex }) => {
  /* dialog close dependencies */
  const handleCloseDialog = () => {
    onClose()
  }
  /* dialog close dependencies */

  return (
    <Modal
      destroyOnClose
      centered
      footer={false}
      open={open}
      onCancel={handleCloseDialog}
      width={1000}
    >
      <Flex vertical gap="1rem">
        <Flex justify="center" style={{ marginTop: 50 }}>
          <Typography.Title level={1} style={{ textDecoration: 'underline' }}>
            {dynamicModaldata?.[dynamicModalIndex]?.title}
          </Typography.Title>
        </Flex>
        <React.Fragment>{dynamicModaldata?.[dynamicModalIndex]?.component}</React.Fragment>
      </Flex>
    </Modal>
  )
}

export default DynamicModal
