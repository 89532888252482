import { Flex } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ArrowDownIcon } from 'assets/svg'
import './SettingsSidebar.scss'

function SettingsSidebar({ menuGroup, collapsed, setCollapsed }) {
  /* location dependencies */
  const location = useLocation()
  console.log(location.state, 'location.state')
  /* location dependencies */

  /* navigation dependencies */
  const navigate = useNavigate()
  /* navigation dependencies */

  /* active side bar menu dependencies */
  const [isActiveMenuId, setIsActiveMenuId] = useState([])
  const [showSubNavigationRoutes, setShowSubNavigationRoutes] = useState({})
  /* active side bar menu dependencies */

  /* function definition to set the active menu when the user clicks on it */
  const handleNavigationClicked = (element) => {
    const tempPathnameArray = location.pathname.split('/').filter(Boolean)

    const filteredPathName = removeMissingItems(tempPathnameArray)(menuGroup)

    let hierarchy
    filteredPathName?.forEach((_element) => {
      hierarchy = findElementHierarchy(menuGroup, _element)
    })
    setIsActiveMenuId(hierarchy)

    if (!hierarchy.some((item) => item.id === element.id)) {
      element?.url &&
        navigate(element?.url, {
          replace: true,
          state: {
            prevPath: [...(location.state?.prevPath || [])]
          }
        })
    }
    if (element.children && element.children?.length > 0) {
      setShowSubNavigationRoutes((prevState) => ({
        ...prevState,
        [element.id]: !prevState[element.id]
        // [element.id]: true
      }))
    } else {
      if (!hierarchy.some((item) => item.id === element.id)) {
        setShowSubNavigationRoutes({})
      }
    }
  }
  /* function definition to set the active menu when the user clicks on it */

  const findElementHierarchy = useCallback((menuGroup, selectedId, hierarchyArr = []) => {
    for (const element of menuGroup) {
      if (element.id === selectedId) {
        return [...hierarchyArr, element]
      }

      if (element.children && element.children.length > 0) {
        const childHierarchy = findElementHierarchy(element.children, selectedId, [
          ...hierarchyArr,
          element
        ])
        if (childHierarchy) {
          return childHierarchy
        }
      }
    }
    return null
  }, [])

  function removeMissingItems(pathNameArr) {
    const existingIdArr = []

    return function traverse(menuGroup) {
      menuGroup.forEach((element) => {
        existingIdArr.push(element.id)
        if (element.children && element.children.length > 0) {
          traverse(element.children)
        }
      })
      return pathNameArr.filter((_element) => existingIdArr.includes(_element))
    }
  }

  /* check if the module tab is already active when the component is mounted */
  useEffect(() => {
    const tempPathnameArray = location.pathname.split('/').filter(Boolean)

    const filteredPathName = removeMissingItems(tempPathnameArray)(menuGroup)

    let hierarchy
    filteredPathName?.forEach((_element) => {
      hierarchy = findElementHierarchy(menuGroup, _element)
    })
    setIsActiveMenuId(hierarchy)

    const tempMenuGroup = menuGroup.filter((element) =>
      tempPathnameArray.some((_element) => _element === element.id)
    )

    if (tempMenuGroup && tempMenuGroup[0]?.children && tempMenuGroup[0]?.children.length > 0) {
      setShowSubNavigationRoutes((prevState) => {
        return {
          ...prevState,
          [tempMenuGroup[0].id]: true
        }
      })
    }
  }, [findElementHierarchy, location.pathname, menuGroup])
  /* check if the module tab is already active when the component is mounted */

  useEffect(() => {
    function handleResizeFunction(e) {
      if (e.currentTarget.innerWidth <= 768) {
        setCollapsed(false)
      }
    }
    window.addEventListener('resize', handleResizeFunction)
    return () => {
      window.removeEventListener('resize', handleResizeFunction)
      setCollapsed(false)
    }
  }, [setCollapsed])

  /* Sidebar sub menu group component */
  const SubMenuGroup = ({ element }) => {
    return element?.map((_element, _index) => {
      return (
        <React.Fragment key={_element.id}>
          <li
            className={`settings-sub-navigation-item-list ${
              isActiveMenuId?.some((e) => e.id === _element?.id) ? `active${_index}` : ''
            }`}
            onClick={() => handleNavigationClicked(_element, _index)}
          >
            {_element.title}
          </li>
          {_element && _element.children?.length > 0 && (
            <SubMenuGroup element={_element.children} />
          )}
        </React.Fragment>
      )
    })
  }
  /* Sidebar sub menu group component */

  return (
    <div
      className={`settings-sidebar-container ${collapsed ? 'settings-sidebar-collapsed' : 'settings-sidebar-open'}`}
    >
      <div className="settings-sidebar-wrapper">
        <ul className="settings-navigation-list">
          {menuGroup?.map((element, index) => {
            return (
              <React.Fragment key={element.id}>
                <li
                  onClick={() => handleNavigationClicked(element, index)}
                  className={`settings-navigation-item-list ${
                    isActiveMenuId?.some((e) => e.id === element?.id) ? `active${index}` : ''
                  }`}
                >
                  <div className="settings-navigation-label">{element.title}</div>
                  {element.children && element.children?.length > 0 && (
                    <div
                      className={`${
                        showSubNavigationRoutes[element.id] === true ? 'toggle-down' : 'toggle-up'
                      }`}
                    >
                      <ArrowDownIcon />
                    </div>
                  )}
                </li>
                {element.children && element.children?.length > 0 && (
                  <Flex
                    vertical
                    className={`settings-sub-navigation-container ${
                      showSubNavigationRoutes[element.id] === true
                        ? 'settings-show-sub-navigation-container'
                        : 'settings-hide-sub-navigation-container'
                    }`}
                    gap="0.5rem"
                  >
                    <SubMenuGroup element={element.children} />
                  </Flex>
                )}
              </React.Fragment>
            )
          })}
        </ul>
      </div>
    </div>
  )
}

export default SettingsSidebar
