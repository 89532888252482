import { Button, Card, Col, Flex, Form, Input, Select, Spin, Typography } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import { BroadCastIcon, MultiSelectPillRemoveIcon } from 'assets/svg'
import { useTranslation } from 'react-i18next'
import {
  inputFieldValidation,
  selectFieldValidation,
  textareaFieldValidation
} from 'utils/formUtil'
import { filterType } from './Data'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppService, BroadCastService } from '../../../redux/services'
import TextArea from 'antd/es/input/TextArea'
import { useNotify } from 'context/NotificationProvider'
import './BroadcastWidget.scss'

const BroadcastWidget = ({ showBroadCast, setShowBroadCast }) => {
  /* i18n dependencies */
  const { t } = useTranslation()
  /* i18n dependencies */

  /* notification dependencies */
  const { showNotification } = useNotify()
  /* notification dependencies */

  /* redux dependencies */
  const dispatch = useDispatch()
  const { loading: appLoading, specialities } = useSelector((state) => state.app)
  const { addBroadCast } = useSelector((state) => state.broadCast)
  /* redux dependencies */

  const handleCloseBroadCast = () => {
    setShowBroadCast((prevState) => !prevState)
  }

  /* payload for API calling */
  let specialitiesPayload = {
    page: 1,
    pageSize: 1000,
    value: ''
  }
  /* payload for API calling */

  /*useEffect hook to fetch initial data when the component mounts */
  useEffect(() => {
    dispatch(AppService.get_specialities(specialitiesPayload))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  /*useEffect hook to fetch initial data when the component mounts */

  /* form dependencies */
  const [form] = Form.useForm()
  const initialValues = {
    filter_type: 'General',
    title: '',
    specialty: null,
    message: ''
  }
  const watchFilterType = Form.useWatch('filter_type', { form, preserve: true })
  /* form dependencies */

  /* submit handler */
  const onSubmit = (values) => {
    const dataToBeSent = {
      ...values
    }
    dispatch(
      BroadCastService.add_broadcast_message({
        payload: dataToBeSent,
        showNotification,
        handleCloseBroadCast
      })
    )
  }
  /* submit handler */

  return (
    <Card
      className={`broadcast-widget-container ${showBroadCast ? 'show-broadcast-widget-card' : 'hide-broadcast-widget-card'}`}
    >
      <Flex vertical gap="1rem">
        <Spin spinning={addBroadCast?.loading}>
          <Flex justify="space-between">
            <Flex align="center" gap={'0.5rem'}>
              <BroadCastIcon />
              <Typography.Title level={2}>{t('BroadcastWidget.UpdateMessage')}</Typography.Title>
            </Flex>
            <Button
              type="primary"
              onClick={handleCloseBroadCast}
              icon={<CloseOutlined />}
              style={{ backgroundColor: 'transparent' }}
            />
          </Flex>
          <Flex vertical gap="1rem">
            <Form
              autoComplete="off"
              layout="vertical"
              form={form}
              initialValues={initialValues}
              onFinish={onSubmit}
            >
              <Col span={24}>
                <Form.Item
                  label={`${t('BroadcastWidget.FilterType')}`}
                  name="filter_type"
                  rules={[...selectFieldValidation(t('BroadcastWidget.FilterType'))]}
                >
                  <Select
                    placeholder={`${t('CommonUtils.Validations.Select')}`}
                    options={filterType}
                    onClick={(event) => event.stopPropagation()}
                  />
                </Form.Item>
              </Col>
              {watchFilterType === 'Specialties' && (
                <Col span={24}>
                  <Form.Item
                    label={t('BroadcastWidget.Specialities')}
                    name="specialty"
                    rules={[...selectFieldValidation(t('BroadcastWidget.Specialities'))]}
                  >
                    <Select
                      placeholder={`${t('CommonUtils.Validations.Select')} ${t('BroadcastWidget.Specialities')}`}
                      options={specialities}
                      mode="multiple"
                      removeIcon={<MultiSelectPillRemoveIcon />}
                      loading={appLoading}
                      showSearch
                      onClick={(event) => event.stopPropagation()}
                    />
                  </Form.Item>
                </Col>
              )}
              <Col span={24}>
                <Form.Item
                  label={t('BroadcastWidget.Title')}
                  name="title"
                  rules={[...inputFieldValidation(t('BroadcastWidget.Title'))]}
                >
                  <Input
                    placeholder={`${t('CommonUtils.Validations.EnterYour')} ${t('BroadcastWidget.Title')}`}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label={t('BroadcastWidget.YourNews')}
                  name="message"
                  rules={[...textareaFieldValidation(t('BroadcastWidget.YourNews'))]}
                >
                  <TextArea rows={4} placeholder={`${t('BroadcastWidget.WriteYourNews')}`} />
                </Form.Item>
              </Col>
              <Flex align="center" justify="end" gap={'1rem'} style={{ marginTop: '1rem' }}>
                <Button type="primary" size="large" onClick={handleCloseBroadCast}>
                  {t('CommonUtils.Cancel')}
                </Button>
                <Button size="large" htmlType="submit">
                  {t('BroadcastWidget.SendUpdate')}
                </Button>
              </Flex>
            </Form>
          </Flex>
        </Spin>
      </Flex>
    </Card>
  )
}

export default BroadcastWidget
