import { AppRoutingConfig } from 'assets/config/AppRoutingConfig'
import {
  DashboardIcon,
  DirectoryIcon,
  HomeIcon,
  MyCommunityIcon,
  MyLibraryIcon,
  MyPatientsIcon,
  MyRequestsIcon,
  SettingsIcon
} from 'assets/svg'

export const physicianSidebarUtils = (t, ref2, ref3, ref4, ref5, ref6) => {
  let menuGroup = [
    {
      id: 'overview',
      title: t('AppModuleNames.Overview'),
      icon: null,
      url: '',
      isSubMenu: true
    },
    {
      id: 'home',
      title: t('AppModuleNames.Home'),
      icon: HomeIcon,
      url: AppRoutingConfig.HOME
    },
    {
      id: 'dashboard',
      title: t('AppModuleNames.Dashboard'),
      icon: DashboardIcon,
      url: AppRoutingConfig.DASHBOARD,
      ref: ref2
    },
    {
      id: 'my-requests',
      title: t('AppModuleNames.MyRequests'),
      icon: MyRequestsIcon,
      url: AppRoutingConfig.MY_REQUESTS,
      ref: ref3
    },
    {
      id: 'my-patients',
      title: t('AppModuleNames.MyPatients'),
      icon: MyPatientsIcon,
      url: AppRoutingConfig.MY_PATIENTS
    },
    {
      id: 'directory',
      title: t('AppModuleNames.Directory'),
      icon: DirectoryIcon,
      url: AppRoutingConfig.DIRECTORY
    },
    {
      id: 'menu',
      title: t('AppModuleNames.Menu'),
      icon: null,
      url: '',
      isSubMenu: true
    },
    // community?.length > 1 || is_tour_open
    //   ? {
    //       id: 'my-community',
    //       title: t('AppModuleNames.MyCommunity'),
    //       icon: MyCommunityIcon,
    //       url: AppRoutingConfig.MY_COMMUNITY,
    //       ref: ref4
    //     }
    //   : null,
    {
      id: 'my-community',
      title: t('AppModuleNames.MyCommunity'),
      icon: MyCommunityIcon,
      url: AppRoutingConfig.MY_COMMUNITY,
      ref: ref4
    },
    {
      id: 'my-library',
      title: t('AppModuleNames.MyLibrary'),
      icon: MyLibraryIcon,
      url: AppRoutingConfig.MY_LIBRARY,
      ref: ref5
    },
    {
      id: 'account',
      title: t('AppModuleNames.Account'),
      icon: null,
      url: '',
      isSubMenu: true
    },
    {
      id: 'settings',
      title: t('AppModuleNames.Settings'),
      icon: SettingsIcon,
      url: AppRoutingConfig.PROFILE_INFORMATION,
      ref: ref6
    }
  ]

  return menuGroup
}
