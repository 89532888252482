import { createSlice } from '@reduxjs/toolkit'

import { month_labels } from 'constants'
import { AppRoutingConfig } from 'assets/config/AppRoutingConfig'
import { week_labels } from 'constants'
import {
  get_daily_active_users,
  get_members_subscribed_graph,
  get_patient_demographics,
  get_physician_demographics,
  get_total_communities_created_graph,
  get_total_revenue_graph
} from '../services/synappAdminDashboardService'

const CYAN = 'rgba(3, 139, 181, 1)'
const PURPLE = 'rgba(91, 115, 232, 1)'

const commonChartData = {
  labels: month_labels,
  options: {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
        align: 'start',
        labels: {
          usePointStyle: true,
          pointStyle: 'circle'
        }
      }
    }
  }
}

const initialState = {
  synappAdminDashboard: {
    loading: false,
    data: [
      {
        id: 'number_of_communities',
        title: 'NumberOfCommunities',
        value: 0,
        category: ['general']
      },
      {
        id: 'number_of_members',
        title: 'NumberOfMembers',
        value: 0,
        category: ['general']
      },
      {
        id: 'tele_expertise_cases',
        title: 'TelexpertiseCases',
        value: 0,
        category: ['general']
      },
      {
        id: 'second_opinion_cases',
        title: 'SecondOpinionCases',
        value: 0,
        category: ['general']
      },
      {
        id: 'total_video_consultation',
        title: 'TotalVideoConsultation',
        value: 0,
        category: ['general', 'user', 'community']
      },
      {
        id: 'profile_validation_request',
        title: 'ProfileValidationRequest',
        value: 0,
        navigateTo: AppRoutingConfig.PROFILE_VALIDATION_REQUEST,
        category: ['general']
      },
      {
        id: 'tele_expertise_requests_sent',
        title: 'TeleExpertiseRequestsSent',
        value: 0,
        category: ['user']
      },
      {
        id: 'tele_expertise_requests_received',
        title: 'TeleExpertiseRequestsReceived',
        value: 0,
        category: ['user', 'community']
      },
      {
        id: 'second_opinion_requests_received',
        title: 'SecondOpinionRequestsReceived',
        value: 0,
        category: ['user', 'community']
      },
      {
        id: 'tele_expertise_total_revenue',
        title: 'TelexpertiseTotalRevenue',
        value: 0,
        category: ['user', 'general', 'community']
      },
      {
        id: 'second_opinion_total_revenue',
        title: 'SecondOpinionTotalRevenue',
        value: 0,
        category: ['user', 'general', 'community']
      },
      {
        id: 'country',
        title: 'Country',
        value: 0,
        category: ['user']
      },
      {
        id: 'communities',
        title: 'Communities',
        value: 0,
        category: ['user']
      },
      {
        id: 'communitiey',
        title: 'Community',
        value: 0,
        category: ['community']
      },
      {
        id: 'members',
        title: 'Members',
        value: 0,
        category: ['community']
      }
    ]
  },
  getDailyActiveUsers: {
    loading: false,
    data: {
      labels: [],
      datasets: [
        {
          label: '',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          borderColor: PURPLE,
          backgroundColor: PURPLE
        }
      ],
      options: commonChartData.options
    }
  },
  getTotalRevenueGraph: {
    loading: false,
    telexpertiseData: {
      labels: commonChartData.labels,
      datasets: [
        {
          label: 'Tele Expertise',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          borderColor: CYAN,
          backgroundColor: CYAN
        }
      ],
      options: commonChartData.options
    },
    secondOpinionData: {
      labels: commonChartData.labels,
      datasets: [
        {
          label: 'Second Opinion',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          borderColor: PURPLE,
          backgroundColor: PURPLE
        }
      ],
      options: commonChartData.options
    }
  },
  getMembersSubscribedGraph: {
    loading: false,
    data: {
      labels: commonChartData.labels,
      datasets: [
        {
          label: '',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          borderColor: CYAN,
          backgroundColor: CYAN
        }
      ],
      options: commonChartData.options
    }
  },
  getTotalCommunitiesCreatedGraph: {
    loading: false,
    data: {
      labels: commonChartData.labels,
      datasets: [
        {
          label: '',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          borderColor: CYAN,
          backgroundColor: CYAN
        }
      ],
      options: commonChartData.options
    }
  },
  getPatientDemographics: {
    loading: false,
    data: null
  },
  getPhysicianDemographics: {
    loading: false,
    data: null
  },
  getTotalRequestsCreatedGraph: {
    loading: false,
    telexpertiseData: {
      labels: commonChartData.labels,
      datasets: [
        {
          label: 'Tele Expertise',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          borderColor: CYAN,
          backgroundColor: CYAN
        }
      ],
      options: commonChartData.options
    },
    secondOpinionData: {
      labels: commonChartData.labels,
      datasets: [
        {
          label: 'Second Opinion',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          borderColor: PURPLE,
          backgroundColor: PURPLE
        }
      ],
      options: commonChartData.options
    }
  },
  getTotalRequestsAcceptedGraph: {
    loading: false,
    telexpertiseData: {
      labels: commonChartData.labels,
      datasets: [
        {
          label: 'Tele Expertise',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          borderColor: CYAN,
          backgroundColor: CYAN
        }
      ],
      options: commonChartData.options
    },
    secondOpinionData: {
      labels: commonChartData.labels,
      datasets: [
        {
          label: 'Second Opinion',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          borderColor: PURPLE,
          backgroundColor: PURPLE
        }
      ],
      options: commonChartData.options
    }
  }
}

export const synappAdminDashboardSlice = createSlice({
  name: 'synappAdminDashboard',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // get daily active users
    builder.addCase(get_daily_active_users.pending, (state) => {
      state.getDailyActiveUsers.loading = true
    })
    builder.addCase(get_daily_active_users.fulfilled, (state, action) => {
      state.getDailyActiveUsers.loading = false
      let modified_arr = []
      const filterValue = action?.payload?.requestPayload?.filter?.toLowerCase()
      state.getDailyActiveUsers.data.datasets[0].label =
        filterValue?.[0]?.toUpperCase() + filterValue?.slice(1)

      let weeklyDataArr = Array(week_labels?.length).fill(0)
      let week_labels_arr = week_labels.map((element) => element.toUpperCase())

      switch (action?.payload?.requestPayload?.filter) {
        case 'DAILY':
          modified_arr =
            action?.payload?.response?.map((element) => {
              const hour = element?.hour_group
              return {
                count: element?.count,
                hour: `${hour}:00`
              }
            }) || []

          state.getDailyActiveUsers.data.labels = modified_arr.map((item) => item.hour)
          state.getDailyActiveUsers.data.datasets[0].data = modified_arr.map((item) => item.count)
          break

        case 'WEEKLY':
          state.getDailyActiveUsers.data.labels = [...week_labels]
          modified_arr = action?.payload?.response
          modified_arr?.forEach((element) => {
            const index = week_labels_arr?.findIndex(
              (_element) => _element === element?.day_of_week?.trim()
            )

            if (index !== -1) {
              weeklyDataArr[index] = element?.count
            }
          })
          state.getDailyActiveUsers.data.datasets[0].data = weeklyDataArr
          break

        case 'MONTHLY':
          state.getDailyActiveUsers.data.labels = [...week_labels]
          modified_arr = action?.payload?.response?.map((element) => {
            const month = new Date(element?.month_group).toLocaleString('en-us', {
              month: 'short',
              year: 'numeric'
            })
            return {
              month: month,
              count: element?.count
            }
          })

          state.getDailyActiveUsers.data.labels = modified_arr.map((item) => item.month)
          state.getDailyActiveUsers.data.datasets[0].data = modified_arr.map((item) => item.count)
          break

        default:
          state.getDailyActiveUsers.data.labels = []
          state.getDailyActiveUsers.data.datasets[0].data = [
            ...initialState.getDailyActiveUsers.data.datasets[0].data
          ]
          break
      }
    })
    builder.addCase(get_daily_active_users.rejected, (state) => {
      state.getDailyActiveUsers.loading = false
    })

    // get total revenue graph
    builder.addCase(get_total_revenue_graph.pending, (state) => {
      state.getTotalRevenueGraph.loading = true
    })
    builder.addCase(get_total_revenue_graph.fulfilled, (state, action) => {
      const createMonthlyDataArray = (data) => {
        const monthlyDataArr = Array(12).fill(0)
        data[0]?.month_wise?.forEach(({ month, revenue }) => {
          monthlyDataArr[month - 1] = revenue
        })
        return monthlyDataArr
      }

      const te = action.payload.response?.te
      const so = action.payload.response?.so

      const newTeDataArr = createMonthlyDataArray(te)
      const newSoDataArr = createMonthlyDataArray(so)

      state.getTotalRevenueGraph.telexpertiseData.datasets[0].data = newTeDataArr
      state.getTotalRevenueGraph.secondOpinionData.datasets[0].data = newSoDataArr

      state.communityDashboard.data = state.communityDashboard.data.map((element) => {
        const getRevenue = (id) => {
          switch (id) {
            case 'telexpertise_total_revenue':
              return Number(te?.[0]?.revenue).toFixed(2)

            case 'second_opinion_total_revenue':
              return Number(so?.[0]?.revenue).toFixed(2)

            default:
              break
          }
        }
        return {
          ...element,
          count: getRevenue(element.id) || 0
        }
      })

      state.getTotalRevenueGraph.loading = false
    })
    builder.addCase(get_total_revenue_graph.rejected, (state) => {
      state.getTotalRevenueGraph.loading = false
    })

    // get members subscribed graph
    builder.addCase(get_members_subscribed_graph.pending, (state) => {
      state.getMembersSubscribedGraph.loading = true
    })
    builder.addCase(get_members_subscribed_graph.fulfilled, (state, action) => {
      const createMonthlyDataArray = (data) => {
        const monthlyDataArr = Array(12).fill(0)
        data[0]?.month_wise?.forEach(({ month, revenue }) => {
          monthlyDataArr[month - 1] = revenue
        })
        return monthlyDataArr
      }

      const newDataArr = createMonthlyDataArray(action.payload.response)

      state.getMembersSubscribedGraph.data.datasets[0].data = newDataArr
      state.getMembersSubscribedGraph.loading = false
    })
    builder.addCase(get_members_subscribed_graph.rejected, (state) => {
      state.getMembersSubscribedGraph.loading = false
    })

    // get total communities created
    builder.addCase(get_total_communities_created_graph.pending, (state) => {
      state.getTotalCommunitiesCreatedGraph.loading = true
    })
    builder.addCase(get_total_communities_created_graph.fulfilled, (state, action) => {
      const createMonthlyDataArray = (data) => {
        const monthlyDataArr = Array(12).fill(0)
        data[0]?.month_wise?.forEach(({ month, revenue }) => {
          monthlyDataArr[month - 1] = revenue
        })
        return monthlyDataArr
      }

      const newDataArr = createMonthlyDataArray(action.payload.response)

      state.getTotalCommunitiesCreatedGraph.data.datasets[0].data = newDataArr
      state.getTotalCommunitiesCreatedGraph.loading = false
    })
    builder.addCase(get_total_communities_created_graph.rejected, (state) => {
      state.getTotalCommunitiesCreatedGraph.loading = false
    })

    // get patient demographics
    builder.addCase(get_patient_demographics.pending, (state) => {
      state.getPatientDemographics.loading = true
    })
    builder.addCase(get_patient_demographics.fulfilled, (state, action) => {
      state.getPatientDemographics.loading = false
      state.getPatientDemographics.data = action?.payload?.response
    })
    builder.addCase(get_patient_demographics.rejected, (state) => {
      state.getPatientDemographics.loading = false
    })

    // get physician demographics
    builder.addCase(get_physician_demographics.pending, (state) => {
      state.getPhysicianDemographics.loading = true
    })
    builder.addCase(get_physician_demographics.fulfilled, (state, action) => {
      state.getPhysicianDemographics.loading = false
      state.getPhysicianDemographics.data = action?.payload?.response
    })
    builder.addCase(get_physician_demographics.rejected, (state) => {
      state.getPhysicianDemographics.loading = false
    })
  }
})

export default synappAdminDashboardSlice.reducer
