import { Popover } from 'antd'

const InfoPopover = ({ popOverText, textLength = 15, popOverProps, popOverContentProps }) => {
  return (
    <>
      {popOverText?.length <= textLength ? (
        popOverText
      ) : (
        <Popover content={<div {...popOverContentProps}>{popOverText}</div>} {...popOverProps}>
          {popOverText?.substring(0, textLength) + ' ...'}
        </Popover>
      )}
    </>
  )
}

export default InfoPopover
