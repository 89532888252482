import { createSlice } from '@reduxjs/toolkit'
import { get_so_upcoming_appointments } from '../services/appointmentsService'

export const appointmentsSlice = createSlice({
  name: 'appointments',
  initialState: {
    soAppointments: {
      loading: false,
      data: null
    }
  },
  extraReducers: (builder) => {
    // Get So Appointments
    builder.addCase(get_so_upcoming_appointments.pending, (state) => {
      state.soAppointments.loading = true
    })
    builder.addCase(get_so_upcoming_appointments.fulfilled, (state, action) => {
      state.soAppointments.loading = false
      state.soAppointments.data = action.payload?.response
    })
    builder.addCase(get_so_upcoming_appointments.rejected, (state) => {
      state.soAppointments.loading = false
    })
  }
})

// Action creators are generated for each case reducer function
// export const {} = appointmentsSlice.actions

export default appointmentsSlice.reducer
