import { Button, Col, Dropdown, Row } from 'antd'
import { ArrowDownIcon, AuthLogo, LanguageIcon } from '../../../assets/svg'
import React, { useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { AppRoutingConfig } from 'assets/config/AppRoutingConfig'
import { languageData } from 'constants'
import i18next from 'i18next'
import { useLanguageChanger } from 'context/LanguageProvider'
import { useSelector } from 'react-redux'
import { roles } from '../../../constants'
import './AuthLayout.scss'

// --> Created-by : thakkarshrey10 and reactive-karan
// --> Dated : 16/04/2024

// The purpose of this component is to provide a common wrapper layout for all the auth pages
// Common layout contains a carousel component with images
// Also some styling is done for the children

const AuthLayout = ({ languageButton = true, carouselArr = [] }) => {
  /* location dependencies */
  const location = useLocation()
  /* location dependencies */

  /* language change dependencies */
  const { setSelectedLanguage } = useLanguageChanger()
  /* language change dependencies */

  const authState = useSelector((state) => state.auth.signup)

  /* navigation dependencies */
  const navigate = useNavigate()
  /* navigation dependencies */

  /* i18n dependencies */
  const { t } = useTranslation()
  /* i18n dependencies */

  /* on dropdown change */
  const handleLanguageDropdownChange = (value) => {
    const updatedLanguageData = Object.keys(languageData)?.map((item) => {
      if (item === value?.key) {
        return { ...languageData[item], active: true }
      } else {
        return { ...languageData[item], active: false }
      }
    })
    const activeLanguage = updatedLanguageData?.filter((item) => item?.active)
    i18next.changeLanguage(value?.key)
    setSelectedLanguage(activeLanguage[0])
  }
  /* on dropdown change */

  const goBackToRoute = () => {
    const goBackToAccountInfo = (role) => {
      switch (role) {
        case roles.physician:
          return AppRoutingConfig.APP_URL_ACCOUNT_INFORMATION
        case roles.hcp:
          return AppRoutingConfig.APP_URL_ACCOUNT_INFORMATION
        case roles.patient:
          return AppRoutingConfig.APP_URL_PATIENT_ACCOUNT_INFORMATION

        default:
          break
      }
    }
    const routeMapping = {
      [AppRoutingConfig.APP_URL_VERIFY_YOUR_IDENTITY]: AppRoutingConfig.APP_URL_LOGIN,
      [AppRoutingConfig.APP_URL_SELECT_SIGNUP_METHOD]:
        AppRoutingConfig.APP_URL_CHOOSE_YOUR_IDENTITY,
      [AppRoutingConfig.APP_URL_SIGN_UP_WITH_RPPS]: AppRoutingConfig.APP_URL_SELECT_SIGNUP_METHOD,
      [AppRoutingConfig.APP_URL_CREATE_PASSWORD]: goBackToAccountInfo(
        authState?.user_details?.identity
      )
    }

    for (const [path, route] of Object.entries(routeMapping)) {
      if (location.pathname.includes(path)) {
        return route
      }
    }
  }

  return (
    <div className="auth-wrapper">
      <Row className="auth-container">
        <Col xs={0} sm={0} lg={11} className="auth-carousel-container">
          <AuthLogo />
          <CustomCarousel speed={5000}>
            {carouselArr?.map((SvgComponent) => (
              <React.Fragment key={SvgComponent}>
                <SvgComponent />
              </React.Fragment>
            ))}
          </CustomCarousel>
        </Col>
        <Col xs={24} sm={24} lg={13} className="auth-content-container">
          <div className="content-wrapper-container">
            {[
              AppRoutingConfig.APP_URL_VERIFY_YOUR_IDENTITY,
              AppRoutingConfig.APP_URL_SELECT_SIGNUP_METHOD,
              AppRoutingConfig.APP_URL_SIGN_UP_WITH_RPPS,
              AppRoutingConfig.APP_URL_CREATE_PASSWORD
            ].includes(location.pathname) && (
              <div className="content-back">
                <Button
                  size="large"
                  type="text"
                  className="add-member-manually-btn"
                  onClick={() => navigate(goBackToRoute())}
                >
                  <ArrowDownIcon iconStyle="add-member-manually-btn-icon" />
                  <p> {t('common.caseStepper.back')}</p>
                </Button>
              </div>
            )}
            <div className="content-container">
              <Outlet />
            </div>
          </div>
        </Col>
      </Row>

      {languageButton && (
        <>
          <div className="float-button-container">
            <Dropdown
              menu={{
                items: Object.keys(languageData).map((element) => {
                  return {
                    label: languageData[element]?.label,
                    value: languageData[element]?.value,
                    key: languageData[element]?.value
                  }
                }),
                onClick: handleLanguageDropdownChange
              }}
              placement="topRight"
              trigger={['click']}
            >
              <Button
                icon={<LanguageIcon />}
                shape="circle"
                type="primary"
                style={{ boxShadow: 'var(--default-box-shadow)', width: '40px', aspectRatio: 1 }}
              />
            </Dropdown>
          </div>
        </>
      )}
    </div>
  )
}

function CarouselComponent({ carouselElement, carouselIndex, activeImage }) {
  return (
    <div
      className={`carousel-card ${carouselIndex === activeImage ? 'carousel-card-active' : ''}`}
      key={carouselIndex}
    >
      {carouselElement}
    </div>
  )
}

function IndicatorComponent({ carouselIndex, activeImage, setActiveImage }) {
  return (
    <div
      key={carouselIndex}
      onClick={() => setActiveImage(carouselIndex)}
      className={`carousel-indicator ${carouselIndex === activeImage ? 'carousel-indicator-active' : ''}`}
      data-testid={`carousel-indicator-${carouselIndex}`}
    ></div>
  )
}

function CustomCarousel({ children, speed = 2000 }) {
  /* carousel dependencies */
  const [activeImage, setActiveImage] = useState(0)
  /* carousel dependencies */

  const carouselElements = React.Children.toArray(children)

  useEffect(() => {
    if (carouselElements.length <= 1) {
      setActiveImage(0)
      return
    }

    const timeout = setTimeout(() => {
      setActiveImage((prevValue) => (prevValue === carouselElements.length - 1 ? 0 : prevValue + 1))
    }, speed)

    return () => {
      clearTimeout(timeout)
    }
  }, [carouselElements.length, activeImage, setActiveImage, speed])

  return (
    <>
      {carouselElements?.map((carouselElement, index) => {
        return (
          <CarouselComponent
            key={index}
            carouselElement={carouselElement}
            carouselIndex={index}
            activeImage={activeImage}
          />
        )
      })}
      <div className="carousel-indicator-container">
        {carouselElements?.map((_, index) => {
          return (
            <IndicatorComponent
              key={index}
              carouselIndex={index}
              activeImage={activeImage}
              setActiveImage={setActiveImage}
            />
          )
        })}
      </div>
    </>
  )
}

export default AuthLayout
