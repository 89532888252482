import { translate } from 'i18n/i18n'

export const titlesArr = [
  {
    label: translate('CommonUtils.Dr'),
    value: 'Dr.'
  },
  {
    label: translate('CommonUtils.Mr'),
    value: 'Mr.'
  },
  {
    label: translate('CommonUtils.Ms'),
    value: 'Ms.'
  },
  {
    label: translate('CommonUtils.Mrs'),
    value: 'Mrs.'
  },
  {
    label: translate('CommonUtils.Pr'),
    value: 'Pr.'
  }
]

export const physicianTitlesArr = [
  {
    label: translate('CommonUtils.Dr'),
    value: 'Dr.'
  },
  {
    label: translate('CommonUtils.Pr'),
    value: 'Pr.'
  }
]

export const patientTitlesArr = [
  {
    label: translate('CommonUtils.Dr'),
    value: 'Dr.'
  },
  {
    label: translate('CommonUtils.Mr'),
    value: 'Mr.'
  },
  {
    label: translate('CommonUtils.Ms'),
    value: 'Ms.'
  },
  {
    label: translate('CommonUtils.Mrs'),
    value: 'Mrs.'
  }
]

export const typesArr = [
  {
    value: 'DE',
    label: 'DE'
  },
  {
    value: 'DES',
    label: 'DES'
  },
  {
    value: 'DIU',
    label: 'DIU'
  },
  {
    value: 'DU',
    label: 'DU'
  },
  {
    value: 'CAPA',
    label: 'CAPA'
  }
]

export const languagesArr = [
  {
    label: 'French',
    value: 'French'
  },
  {
    label: 'English',
    value: 'English'
  }
]

export const countriesArr = [
  {
    label: 'India',
    value: 'India'
  },
  {
    label: 'France',
    value: 'France'
  }
]

export const radioButtonOption = [
  {
    label: translate('CommonUtils.Yes'),
    value: true
  },
  {
    label: translate('CommonUtils.No'),
    value: false
  }
]

export const month_labels = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]

export const week_labels = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday'
]
