import { createSlice } from '@reduxjs/toolkit'
import {
  begin_reset_password,
  confirm_reset_password,
  end_signup,
  login,
  send_otp_for_community_email,
  send_otp_for_community_phone,
  send_otp_for_phone,
  resend_otp_for_phone,
  send_otp_for_email,
  resend_otp_for_email,
  signup,
  signup_with_rpps,
  validate_signup,
  verify_login,
  verify_otp_for_community_email,
  verify_otp_for_community_phone,
  get_terms_and_conditions_list,
  get_privacy_policy_list,
  verify_otp_for_email,
  verify_otp_for_phone
} from '../services/authService'
import { encryptData } from 'assets/config/AppEncryptDecryptConfig'
import { AppPermissionsConfig } from 'assets/config/AppPermissionsConfig'
import notEmptyStringOrArray from 'utils/notEmptyString'

const initialState = {
  login: {
    loading: false,
    identifier: '',
    method: '',
    login_flow_id: '',
    flow_id: '',
    password: ''
  },
  signup: {
    loading: false,
    registration_flow_id: '',
    user_details: {
      identity: '',
      firstname: '',
      lastname: '',
      password: '',
      type: '',
      profession: '',
      speciality: '',
      rpps_number: null,
      country_code: '',
      city: '',
      place_of_work: '',
      country: '',
      email: '',
      address: '',
      phone: '',
      gender: '',
      dob: '',
      zipcode: ''
    }
  },
  sendOtpForPhone: {
    loading: false,
    flow_id: ''
  },
  sendOtpForEmail: {
    loading: false,
    flow_id: ''
  },
  sendOtpForCommunityEmail: {
    flow_id: '',
    loading: false
  },
  verifyOtpForCommunityEmail: {
    loading: false
  },
  sendOtpForCommunityPhone: {
    flow_id: '',
    loading: false
  },
  verifyOtpForCommunityPhone: {
    loading: false
  },
  endSignup: {
    loading: false
  },
  resetPassword: {
    loading: false,
    flow_id: '',
    identifier: '',
    method: ''
  },
  confirmResetPassword: {
    loading: false
  },
  endLogin: {
    data: null,
    loading: false
  },
  validateSignup: {
    loading: false
  },
  signupWithRpps: {
    loading: false
  },
  getTermsAndConditions: {
    loading: false,
    data: null
  },
  getPrivacyPolicy: {
    loading: false,
    data: null
  },
  resendMobileOtp: {
    loading: false
  },
  resendEmailOtp: {
    loading: false
  },
  verifyOtpForEmail: {
    loading: false
  },
  verifyOtpForPhone: {
    loading: false
  }
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    selectIdentity: (state, action) => {
      state.signup.user_details.identity = action.payload
    },
    seedUserDetails: (state, action) => {
      state.signup.user_details.firstname = action.payload?.firstname
      state.signup.user_details.lastname = action.payload?.lastname
      state.signup.user_details.email = action.payload?.email
      state.signup.user_details.phone = action.payload?.phone
      state.signup.user_details.gender = action.payload?.gender
      state.signup.user_details.dob = action.payload?.dob
      state.signup.user_details.country = action.payload?.country
      state.signup.user_details.zipcode = action.payload?.zipcode
      state.signup.user_details.address = action.payload?.address
      state.signup.user_details.title = action.payload?.title
      state.signup.user_details.type = state.signup.user_details?.identity
      state.signup.user_details.profession = action.payload?.profession
      state.signup.user_details.speciality = action.payload?.speciality
      state.signup.user_details.city = action.payload?.city
      state.signup.user_details.place_of_work = action.payload?.place_of_work
    },
    clearSignupUserDetails: (state) => {
      state.signup.user_details = initialState.signup.user_details
    },
    clearSignupUserDetailsExceptEmailPhoneIdentity: (state) => {
      state.signup.user_details = {
        ...initialState.signup.user_details,
        email: state.signup.user_details.email,
        phone: state.signup.user_details.phone,
        identity: state.signup.user_details.identity,
        type: state.signup.user_details.type,
        country_code: state.signup.user_details.country_code
      }
    },
    removeBroadCastMessage: (state) => {
      state.endLogin.data.broad_cast_msg = null
    }
  },
  extraReducers: (builder) => {
    // login
    builder.addCase(login.pending, (state) => {
      state.login.loading = true
    })
    builder.addCase(login.fulfilled, (state, action) => {
      state.login.method = action.payload?.requestPayload.method
      state.login.identifier = action.payload?.requestPayload.identifier
      state.login.password = action.payload?.requestPayload.password
      state.login.login_flow_id = action.payload?.response.login_flow_id
      state.login.flow_id = action.payload?.response.flow_id
      state.login.loading = false
    })

    // End sign up
    builder.addCase(end_signup.pending, (state) => {
      state.endSignup.loading = true
    })
    builder.addCase(end_signup.fulfilled, (state) => {
      state.endSignup.loading = false
    })
    builder.addCase(end_signup.rejected, (state) => {
      state.endSignup.loading = false
    })

    // Sign up step 1 Validate Sign up
    builder.addCase(signup.pending, (state) => {
      state.signup.loading = true
    })
    builder.addCase(signup.rejected, (state) => {
      state.signup.loading = false
    })

    builder.addCase(signup.fulfilled, (state, action) => {
      state.signup.user_details = {
        ...initialState.signup.user_details,
        phone: action.payload?.requestPayload?.phone,
        country_code: action.payload?.requestPayload?.country_code,
        email: action.payload?.requestPayload?.email
      }
      state.signup.registration_flow_id = action.payload?.response.registration_flow_id
      state.signup.loading = false
    })

    // validate signup
    builder.addCase(validate_signup.pending, (state) => {
      state.validateSignup.loading = true
    })
    builder.addCase(validate_signup.fulfilled, (state) => {
      state.validateSignup.loading = false
    })
    builder.addCase(validate_signup.rejected, (state) => {
      state.validateSignup.loading = false
    })

    // Send OTP for phone
    builder.addCase(send_otp_for_phone.pending, (state) => {
      state.sendOtpForPhone.loading = true
    })
    builder.addCase(send_otp_for_phone.fulfilled, (state, action) => {
      state.sendOtpForPhone.flow_id = action.payload?.response?.flow_id
      state.sendOtpForPhone.loading = false
    })
    builder.addCase(send_otp_for_phone.rejected, (state) => {
      state.sendOtpForPhone.loading = false
    })

    // Resend OTP for phone
    builder.addCase(resend_otp_for_phone.pending, (state) => {
      state.resendMobileOtp.loading = true
    })
    builder.addCase(resend_otp_for_phone.fulfilled, (state) => {
      state.resendMobileOtp.loading = false
    })
    builder.addCase(resend_otp_for_phone.rejected, (state) => {
      state.resendMobileOtp.loading = false
    })

    // Send OTP for email
    builder.addCase(send_otp_for_email.pending, (state) => {
      state.sendOtpForEmail.loading = true
    })
    builder.addCase(send_otp_for_email.fulfilled, (state, action) => {
      state.sendOtpForEmail.flow_id = action.payload?.response?.flow_id
      state.sendOtpForEmail.loading = false
    })
    builder.addCase(send_otp_for_email.rejected, (state) => {
      state.sendOtpForEmail.loading = false
    })

    // Resend OTP for email
    builder.addCase(resend_otp_for_email.pending, (state) => {
      state.resendEmailOtp.loading = true
    })
    builder.addCase(resend_otp_for_email.fulfilled, (state) => {
      state.resendEmailOtp.loading = false
    })
    builder.addCase(resend_otp_for_email.rejected, (state) => {
      state.resendEmailOtp.loading = false
    })

    // Send OTP for community email
    builder.addCase(send_otp_for_community_email.pending, (state) => {
      state.sendOtpForCommunityEmail.loading = true
    })
    builder.addCase(send_otp_for_community_email.fulfilled, (state, action) => {
      state.sendOtpForCommunityEmail.flow_id = action.payload?.response?.flow_id
      state.sendOtpForCommunityEmail.loading = false
    })
    builder.addCase(send_otp_for_community_email.rejected, (state) => {
      state.sendOtpForCommunityEmail.loading = false
    })

    // Verify OTP for community email
    builder.addCase(verify_otp_for_community_email.pending, (state) => {
      state.verifyOtpForCommunityEmail.loading = true
    })
    builder.addCase(verify_otp_for_community_email.fulfilled, (state) => {
      state.verifyOtpForCommunityEmail.loading = false
    })
    builder.addCase(verify_otp_for_community_email.rejected, (state) => {
      state.verifyOtpForCommunityEmail.loading = false
    })

    // Send OTP for community phone
    builder.addCase(send_otp_for_community_phone.pending, (state) => {
      state.sendOtpForCommunityPhone.loading = true
    })
    builder.addCase(send_otp_for_community_phone.fulfilled, (state, action) => {
      state.sendOtpForCommunityPhone.flow_id = action.payload?.response?.flow_id
      state.sendOtpForCommunityPhone.loading = false
    })
    builder.addCase(send_otp_for_community_phone.rejected, (state) => {
      state.sendOtpForCommunityPhone.loading = false
    })

    // Verify OTP for community phone
    builder.addCase(verify_otp_for_community_phone.pending, (state) => {
      state.verifyOtpForCommunityPhone.loading = true
    })
    builder.addCase(verify_otp_for_community_phone.fulfilled, (state) => {
      state.verifyOtpForCommunityPhone.loading = false
    })
    builder.addCase(verify_otp_for_community_phone.rejected, (state) => {
      state.verifyOtpForCommunityPhone.loading = false
    })

    // Sign up step 2 with RPPS
    builder.addCase(signup_with_rpps.pending, (state) => {
      state.signupWithRpps.loading = true
    })
    builder.addCase(signup_with_rpps.fulfilled, (state, action) => {
      state.signup.user_details.rpps_number = action.payload?.requestPayload?.filters?.rpps
      state.signup.user_details.firstname = action.payload?.response?.results?.[0]?.firstname
      state.signup.user_details.is_first_name_from_rpps = notEmptyStringOrArray(
        action.payload?.response?.results?.[0]?.firstname
      )
      state.signup.user_details.lastname = action.payload?.response?.results?.[0]?.lastname
      state.signup.user_details.is_last_name_from_rpps = notEmptyStringOrArray(
        action.payload?.response?.results?.[0]?.lastname
      )
      state.signup.user_details.place_of_work =
        action.payload?.response?.results?.[0]?.institution_names
      state.signup.user_details.is_place_of_work_from_rpps = notEmptyStringOrArray(
        action.payload?.response?.results?.[0]?.institution_names
      )
      state.signup.user_details.city = action.payload?.response?.results?.[0]?.cities
      state.signup.user_details.is_city_from_rpps = notEmptyStringOrArray(
        action.payload?.response?.results?.[0]?.cities
      )
      state.signup.user_details.profession = action.payload?.response?.results?.[0]?.professions
      state.signup.user_details.is_profession_from_rpps = notEmptyStringOrArray(
        action.payload?.response?.results?.[0]?.professions
      )
      state.signup.user_details.speciality = action.payload?.response?.results?.[0]?.specialities
      state.signup.user_details.is_speciality_from_rpps = notEmptyStringOrArray(
        action.payload?.response?.results?.[0]?.specialities
      )
      state.signupWithRpps.loading = false
    })
    builder.addCase(signup_with_rpps.rejected, (state) => {
      state.signupWithRpps.loading = false
    })

    // verify_login
    builder.addCase(verify_login.pending, (state) => {
      state.endLogin.loading = true
    })

    builder.addCase(verify_login.fulfilled, (state, action) => {
      const response = action.payload?.response

      const permissions = AppPermissionsConfig.getAdminPermissionsObjFromArr(
        response,
        response?.auth_user_role?.sub_role || response?.auth_user_role?.role || ''
      )

      const userData = {
        ...response,
        isAuthenticated: true,
        label: 'Individual',
        value: response?.id,
        permissions
      }

      state.endLogin.data = userData
      encryptData('user', userData)
      encryptData('selectedProfile', userData)
      state.endLogin.loading = false
    })
    builder.addCase(verify_login.rejected, (state) => {
      state.endLogin.loading = false
    })

    // begin reset password
    builder.addCase(begin_reset_password.pending, (state) => {
      state.resetPassword.loading = true
    })
    builder.addCase(begin_reset_password.fulfilled, (state, action) => {
      state.resetPassword.flow_id = action.payload?.response?.flow_id
      state.resetPassword.identifier = action.payload?.requestPayload?.identifier
      state.resetPassword.method = action.payload?.requestPayload?.method
      state.resetPassword.loading = false
    })
    builder.addCase(begin_reset_password.rejected, (state) => {
      state.resetPassword.loading = false
    })

    // begin reset password
    builder.addCase(confirm_reset_password.pending, (state) => {
      state.confirmResetPassword.loading = true
    })
    builder.addCase(confirm_reset_password.fulfilled, (state) => {
      state.confirmResetPassword.loading = false
    })
    builder.addCase(confirm_reset_password.rejected, (state) => {
      state.confirmResetPassword.loading = false
    })

    // Get terms and conditions List
    builder.addCase(get_terms_and_conditions_list.pending, (state) => {
      state.getTermsAndConditions.loading = true
    })
    builder.addCase(get_terms_and_conditions_list.fulfilled, (state, action) => {
      state.getTermsAndConditions.data = action.payload?.response
      state.getTermsAndConditions.loading = false
    })
    builder.addCase(get_terms_and_conditions_list.rejected, (state) => {
      state.getTermsAndConditions.loading = false
    })

    // Get privacy policy List
    builder.addCase(get_privacy_policy_list.pending, (state) => {
      state.getPrivacyPolicy.loading = true
    })
    builder.addCase(get_privacy_policy_list.fulfilled, (state, action) => {
      state.getPrivacyPolicy.data = action.payload?.response
      state.getPrivacyPolicy.loading = false
    })
    builder.addCase(get_privacy_policy_list.rejected, (state) => {
      state.getPrivacyPolicy.loading = false
    })

    // Verify OTP for email
    builder.addCase(verify_otp_for_email.pending, (state) => {
      state.verifyOtpForEmail.loading = true
    })
    builder.addCase(verify_otp_for_email.fulfilled, (state) => {
      state.verifyOtpForEmail.loading = false
    })
    builder.addCase(verify_otp_for_email.rejected, (state) => {
      state.verifyOtpForEmail.loading = false
    })

    // Verify OTP for phone
    builder.addCase(verify_otp_for_phone.pending, (state) => {
      state.verifyOtpForPhone.loading = true
    })
    builder.addCase(verify_otp_for_phone.fulfilled, (state) => {
      state.verifyOtpForPhone.loading = false
    })
    builder.addCase(verify_otp_for_phone.rejected, (state) => {
      state.verifyOtpForPhone.loading = false
    })
  }
})

// Action creators are generated for each case reducer function
export const {
  selectIdentity,
  seedUserDetails,
  clearSignupUserDetails,
  clearSignupUserDetailsExceptEmailPhoneIdentity,
  removeBroadCastMessage
} = authSlice.actions

export default authSlice.reducer
