import { createSlice } from '@reduxjs/toolkit'
import {
  get_request_management,
  update_request_management,
  get_questionnaire,
  update_questionnaire,
  get_tele_expertise_instructions,
  update_tele_expertise_instructions,
  get_second_opinion_instructions,
  update_second_opinion_instructions,
  get_community_request_management,
  get_community_questionnaire,
  update_community_questionnaire,
  get_community_questionnaire_speciality
} from '../services/requestManagementService'
import { decryptData, encryptData } from 'assets/config/AppEncryptDecryptConfig'

export const requestManagementSlice = createSlice({
  name: 'requestManagement',
  initialState: {
    requestManagement: {
      loading: false,
      request_management_info: {}
    },
    communityRequestManagement: {
      loading: false,
      data: null
    },
    updateRequestManagement: {
      loading: false
    },
    questionnaire: {
      loading: false,
      questionnaire_info: []
    },
    updateQuestionnaire: {
      loading: false
    },
    communityQuestionnaire: {
      loading: false,
      data: []
    },
    communityQuestionnaireSpeciality: {
      loading: false,
      data: []
    },
    updateCommunityQuestionnaire: {
      loading: false
    },
    teleExpertiseInsructions: {
      loading: false,
      tele_expertise_info: []
    },
    updateTeleExpertiseInsructions: {
      loading: false
    },
    secondOpinionInsructions: {
      loading: false,
      second_opinion_info: []
    },
    updateSecondOpinionInsructions: {
      loading: false
    }
  },
  extraReducers: (builder) => {
    // Get Request Management Info
    builder.addCase(get_request_management.pending, (state) => {
      state.requestManagement.loading = true
    })
    builder.addCase(get_request_management.fulfilled, (state, action) => {
      state.requestManagement.loading = false
      state.requestManagement.request_management_info = action.payload?.response
    })
    builder.addCase(get_request_management.rejected, (state) => {
      state.requestManagement.loading = false
    })

    // Get community Request Management Info
    builder.addCase(get_community_request_management.pending, (state) => {
      state.communityRequestManagement.loading = true
    })
    builder.addCase(get_community_request_management.fulfilled, (state, action) => {
      state.communityRequestManagement.loading = false
      state.communityRequestManagement.data = action.payload?.response
    })
    builder.addCase(get_community_request_management.rejected, (state) => {
      state.communityRequestManagement.loading = false
    })

    // Update Request Management Info
    builder.addCase(update_request_management.pending, (state) => {
      state.updateRequestManagement.loading = true
    })
    builder.addCase(update_request_management.fulfilled, (state) => {
      state.updateRequestManagement.loading = false

      const user_data = decryptData('user')
      const modified_user_data = { ...user_data }
      if (modified_user_data) modified_user_data.is_req_management_updated = true
      encryptData('user', modified_user_data)
    })
    builder.addCase(update_request_management.rejected, (state) => {
      state.updateRequestManagement.loading = false
    })

    // Get Questionnaire Info
    builder.addCase(get_questionnaire.pending, (state) => {
      state.questionnaire.loading = true
    })
    builder.addCase(get_questionnaire.fulfilled, (state, action) => {
      state.questionnaire.loading = false
      state.questionnaire.questionnaire_info = action.payload?.response
    })
    builder.addCase(get_questionnaire.rejected, (state) => {
      state.questionnaire.loading = false
    })

    // Update Questionnaire Info
    builder.addCase(update_questionnaire.pending, (state) => {
      state.updateQuestionnaire.loading = true
    })
    builder.addCase(update_questionnaire.fulfilled, (state) => {
      state.updateQuestionnaire.loading = false
    })
    builder.addCase(update_questionnaire.rejected, (state) => {
      state.updateQuestionnaire.loading = false
    })

    // Get Community Questionnaire Info
    builder.addCase(get_community_questionnaire.pending, (state) => {
      state.communityQuestionnaire.loading = true
    })
    builder.addCase(get_community_questionnaire.fulfilled, (state, action) => {
      state.communityQuestionnaire.loading = false
      state.communityQuestionnaire.data = action.payload?.response
    })
    builder.addCase(get_community_questionnaire.rejected, (state) => {
      state.communityQuestionnaire.loading = false
    })

    // Get Community Questionnaire speciality
    builder.addCase(get_community_questionnaire_speciality.pending, (state) => {
      state.communityQuestionnaireSpeciality.loading = true
    })
    builder.addCase(get_community_questionnaire_speciality.fulfilled, (state, action) => {
      state.communityQuestionnaireSpeciality.loading = false
      state.communityQuestionnaireSpeciality.data = action.payload?.response
    })
    builder.addCase(get_community_questionnaire_speciality.rejected, (state) => {
      state.communityQuestionnaireSpeciality.loading = false
    })

    // Update Community Questionnaire Info
    builder.addCase(update_community_questionnaire.pending, (state) => {
      state.updateCommunityQuestionnaire.loading = true
    })
    builder.addCase(update_community_questionnaire.fulfilled, (state) => {
      state.updateCommunityQuestionnaire.loading = false
    })
    builder.addCase(update_community_questionnaire.rejected, (state) => {
      state.updateCommunityQuestionnaire.loading = false
    })

    // Get Tele Expertise Instructions
    builder.addCase(get_tele_expertise_instructions.pending, (state) => {
      state.teleExpertiseInsructions.loading = true
    })
    builder.addCase(get_tele_expertise_instructions.fulfilled, (state, action) => {
      state.teleExpertiseInsructions.loading = false
      state.teleExpertiseInsructions.tele_expertise_info = action.payload?.response
    })
    builder.addCase(get_tele_expertise_instructions.rejected, (state) => {
      state.teleExpertiseInsructions.loading = false
    })

    // Update Tele Expertise Instructions
    builder.addCase(update_tele_expertise_instructions.pending, (state) => {
      state.updateTeleExpertiseInsructions.loading = true
    })
    builder.addCase(update_tele_expertise_instructions.fulfilled, (state) => {
      state.updateTeleExpertiseInsructions.loading = false
    })
    builder.addCase(update_tele_expertise_instructions.rejected, (state) => {
      state.updateTeleExpertiseInsructions.loading = false
    })

    // Get Second Opinion Instructions
    builder.addCase(get_second_opinion_instructions.pending, (state) => {
      state.secondOpinionInsructions.loading = true
    })
    builder.addCase(get_second_opinion_instructions.fulfilled, (state, action) => {
      state.secondOpinionInsructions.loading = false
      state.secondOpinionInsructions.second_opinion_info = action.payload?.response
    })
    builder.addCase(get_second_opinion_instructions.rejected, (state) => {
      state.secondOpinionInsructions.loading = false
    })

    // Update Second Opinion Instructions
    builder.addCase(update_second_opinion_instructions.pending, (state) => {
      state.updateSecondOpinionInsructions.loading = true
    })
    builder.addCase(update_second_opinion_instructions.fulfilled, (state) => {
      state.updateSecondOpinionInsructions.loading = false
    })
    builder.addCase(update_second_opinion_instructions.rejected, (state) => {
      state.updateSecondOpinionInsructions.loading = false
    })
  }
})

// Action creators are generated for each case reducer function
// export const {} = requestManagementSlice.actions

export default requestManagementSlice.reducer
