import JoditEditor from 'jodit-react'
import { useMemo, useRef, useState } from 'react'
import { richTextEditorConfig } from './richTextEditorConfig'
import EmojiPickerDialog from './EmojiPickerDialog'
import './RichTextEditor.scss'
import { Spin } from 'antd'

const RichTextEditor = ({ value, setValue, loading }) => {
  const [emojiPickerDialogOpen, setEmojiPickerDialogOpen] = useState(false)

  let editorInstance = useRef(null)

  /* Default editor config dependencies */
  const editorConfig = {
    ...richTextEditorConfig,
    buttons: [...richTextEditorConfig.buttons],
    extraButtons: [],
    uploader: {
      insertImageAsBase64URI: true
    },
    disableDefaultStyles: true,
    events: {
      afterInit: (instance) => {
        editorInstance.current = instance
      }
    }
  }
  /* Default editor config dependencies */

  const handleGetEmoji = (emoji) => {
    editorInstance?.current?.selection?.insertHTML(`${emoji}`)
  }

  const emojiPickerProps = useMemo(
    () => ({
      open: emojiPickerDialogOpen,
      onClose: () => setEmojiPickerDialogOpen(false),
      handleGetEmoji
    }),
    [emojiPickerDialogOpen]
  )

  return (
    <Spin spinning={loading}>
      <div className="rich-text-editor-container">
        <JoditEditor
          config={editorConfig}
          value={value}
          onBlur={(newContent) => setValue(newContent)}
        />
        <EmojiPickerDialog {...emojiPickerProps} />
      </div>
    </Spin>
  )
}
export default RichTextEditor
