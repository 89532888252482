import { createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from 'axiosInstance'
// import { displayMultipleNotifications } from 'utils/notificationsUtils'

// get so patient home statistics
export const get_so_patient_home_statistics = createAsyncThunk(
  'patientHome/get_so_patient_home_statistics',
  async ({ payload = {} }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/so/patient-home`, payload)
      if (response.status === 0) {
        return { requestPayload: payload, response: response.data }
      }
      // if (response.status === 1) {
      //   displayMultipleNotifications(response.data, showNotification)
      // }
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)
