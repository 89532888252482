import CookiePolicy from 'components/pages/features/synapp-admin/settings/configure-policies/cookie-policy/CookiePolicy'
import Disclaimer from 'components/pages/features/synapp-admin/settings/configure-policies/disclaimer/Disclaimer'
import Faq from 'components/pages/features/synapp-admin/settings/configure-policies/faqs/Faq'
import PrivacyPolicy from 'components/pages/features/synapp-admin/settings/configure-policies/privacy-policy/PrivacyPolicy'
import TermsOfUse from 'components/pages/features/synapp-admin/settings/configure-policies/terms-of-use/TermsOfUse'
import { translate } from 'i18n/i18n'

export const sidebarWidgetData = [
  {
    label: 'Faq',
    title: translate('SidebarWidget.FrequentlyAskedQuestions'),
    component: <Faq isSideBarWidget={true} />
  },
  {
    label: 'AboutUs',
    title: translate('SidebarWidget.AboutUs'),
    component: <h1 style={{ textAlign: 'center' }}>Coming Soon...</h1>
  },
  {
    label: 'PrivacyPolicy',
    title: translate('SidebarWidget.PrivacyPolicy'),
    component: <PrivacyPolicy isSideBarWidget={true} />
  },
  {
    label: 'TermsOfUse',
    title: translate('SidebarWidget.TermsOfUse'),
    component: <TermsOfUse isSideBarWidget={true} />
  },
  {
    label: 'Disclaimer',
    title: translate('SidebarWidget.Disclaimer'),
    component: <Disclaimer isSideBarWidget={true} />
  },
  {
    label: 'CookiePolicy',
    title: translate('SidebarWidget.CookiePolicy'),
    component: <CookiePolicy isSideBarWidget={true} />
  }
]
