import { createSlice } from '@reduxjs/toolkit'
import {
  get_home_statistics,
  get_requests_counts,
  get_top5_widget_counts
} from '../services/authorEditorHomeSerivce'

const initialState = {
  homeStatistics: {
    loading: false,
    data: [
      {
        id: 'total_members',
        title: 'NoOfMembersInCommunity',
        count: 0
      },
      {
        id: 'te_total_requests_received',
        title: 'TelexpertiseRequestsReceived',
        count: 0
      },
      {
        id: 'te_requests_on_hold',
        title: 'TelexpertiseRequestsOnHold',
        count: 0
      },
      {
        id: 'te_meantime_to_reply',
        title: 'TelextertiseRequestResponseMeanTime',
        count: 0
      },
      {
        id: 'so_total_requests_received',
        title: 'SecondOpinionRequestsReceived',
        count: 0
      },
      {
        id: 'so_requests_on_hold',
        title: 'SecondOpinionRequestsOnHold',
        count: 0
      },
      {
        id: 'so_meantime_to_reply',
        title: 'SecondOpinionRequestResponseMeanTime',
        count: 0
      },
      {
        id: 'total_video_consumption',
        title: 'TotalVideoConsultation',
        count: 0
      },
      {
        id: 'total_new_members_request',
        title: 'NewMembersValidationRequest',
        count: 0
      }
    ]
  },
  top5WidgetCounts: {
    data: null,
    loading: false
  },
  getRequestsCounts: {
    data: null,
    loading: false
  }
}
export const authorEditorHomeSlice = createSlice({
  name: 'authorEditorHome',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Home statistics
    builder.addCase(get_home_statistics.pending, (state) => {
      state.homeStatistics.loading = true
    })
    builder.addCase(get_home_statistics.fulfilled, (state, action) => {
      const { community = {}, so = {}, te = {} } = action.payload.response || {}
      const responseMapping = {
        total_members: community?.total_members,
        te_total_requests_received: te?.total_requrest_received,
        te_requests_on_hold: te?.total_onhold_requrest,
        te_meantime_to_reply: so?.meantime_to_reply,
        so_total_requests_received: so?.total_requrest_received,
        so_requests_on_hold: so?.total_onhold_requrest,
        so_meantime_to_reply: so?.meantime_to_reply,
        total_video_consumption: so?.total_video_consumption,
        total_new_members_request: community?.total_new_members_request
      }
      state.homeStatistics.data = state.homeStatistics.data?.map((item) => {
        return {
          ...item,
          count: +responseMapping[item.id] || 0
        }
      })
      state.homeStatistics.loading = false
    })
    builder.addCase(get_home_statistics.rejected, (state) => {
      state.homeStatistics.loading = false
    })

    // top 5 widget counts
    builder.addCase(get_top5_widget_counts.pending, (state) => {
      state.top5WidgetCounts.loading = true
    })
    builder.addCase(get_top5_widget_counts.fulfilled, (state, action) => {
      state.top5WidgetCounts.data = action.payload.response
      state.top5WidgetCounts.loading = false
    })
    builder.addCase(get_top5_widget_counts.rejected, (state) => {
      state.top5WidgetCounts.loading = false
    })

    // get requests counts
    builder.addCase(get_requests_counts.pending, (state) => {
      state.getRequestsCounts.loading = true
    })
    builder.addCase(get_requests_counts.fulfilled, (state, action) => {
      state.getRequestsCounts.data = action.payload.response
      state.getRequestsCounts.loading = false
    })
    builder.addCase(get_requests_counts.rejected, (state) => {
      state.getRequestsCounts.loading = false
    })
  }
})

export default authorEditorHomeSlice.reducer
