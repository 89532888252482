import { createSlice } from '@reduxjs/toolkit'
import {
  accept_reject_profile_validation_request,
  get_diploma_list,
  get_profile_info,
  get_profile_validation_request,
  global_search,
  profile_validation,
  update_profile_info,
  update_synapp_admin_profile_info
} from '../services/profileInfoService'

export const profileInfoSlice = createSlice({
  name: 'profileInfo',
  initialState: {
    diplomaList: [],
    loading: false,
    profileInfo: {
      loading: false,
      user_profile_info: null
    },
    updateProfileInfo: {
      loading: false
    },
    updateSynappProfileInfo: {
      loading: false
    },
    profileValidation: {
      loading: false
    },
    getProfileValidationRequest: {
      loading: false,
      data: null,
      pagination: null
    },
    acceptRejectProfileValidationRequest: {
      loading: false
    },
    globalSearchList: {
      loading: false,
      data: []
    }
  },
  reducers: {
    clearProfileInfo: (state) => {
      state.profileInfo.loading = false
      state.profileInfo.user_profile_info = null
    },
    clearGlobalSearchList: (state) => {
      state.globalSearchList = {
        loading: false,
        data: []
      }
    }
  },
  extraReducers: (builder) => {
    // Get diploma list
    builder.addCase(get_diploma_list.pending, (state) => {
      state.loading = true
    })
    builder.addCase(get_diploma_list.fulfilled, (state, action) => {
      state.loading = false
      state.diplomaList = action.payload?.response?.data?.map((list) => {
        return {
          label: list.display_name,
          value: list.value
        }
      })
    })
    builder.addCase(get_diploma_list.rejected, (state) => {
      state.loading = false
    })

    // Get Profile Info
    builder.addCase(get_profile_info.pending, (state) => {
      state.profileInfo.loading = true
    })
    builder.addCase(get_profile_info.fulfilled, (state, action) => {
      state.profileInfo.loading = false
      state.profileInfo.user_profile_info = action.payload?.response
    })
    builder.addCase(get_profile_info.rejected, (state) => {
      state.profileInfo.loading = false
    })

    // Update Profile Info
    builder.addCase(update_profile_info.pending, (state) => {
      state.updateProfileInfo.loading = true
    })

    builder.addCase(update_profile_info.fulfilled, (state) => {
      state.updateProfileInfo.loading = false
    })

    builder.addCase(update_profile_info.rejected, (state) => {
      state.updateProfileInfo.loading = false
    })

    // Update Synapp Profile Info
    builder.addCase(update_synapp_admin_profile_info.pending, (state) => {
      state.updateSynappProfileInfo.loading = true
    })

    builder.addCase(update_synapp_admin_profile_info.fulfilled, (state) => {
      state.updateSynappProfileInfo.loading = false
    })

    builder.addCase(update_synapp_admin_profile_info.rejected, (state) => {
      state.updateSynappProfileInfo.loading = false
    })

    // get profile validation request
    builder.addCase(get_profile_validation_request.pending, (state) => {
      state.getProfileValidationRequest.loading = true
    })

    builder.addCase(get_profile_validation_request.fulfilled, (state, action) => {
      state.getProfileValidationRequest.data = action.payload?.response

      const updated_physician_data = action.payload?.response?.data?.map((item) => {
        const {
          auth_user = {},
          bio = '',
          has_favorite,
          languages,
          physician_level = '',
          profession_type = {},
          specialty_type = {},
          specialty_slug
        } = item?.physician_hcp_info || {}

        return {
          id: item?.id,
          userNo: auth_user?.user_no,
          isLoading: false,
          isVerified: auth_user?.is_profile_verified,
          profileImage: item?.profile_pic_url,
          profileName: `${auth_user?.title} ${auth_user?.first_name} ${auth_user?.last_name}`,
          isFavourite: has_favorite ? true : false,
          onHoliday: false,
          wasActive:
            auth_user?.account_setting?.status === 'available' &&
            auth_user?.account_setting?.replies_within
              ? true
              : false,
          repliesWithIn: auth_user?.account_setting?.replies_within,
          isExpert: physician_level && physician_level !== 'None' ? true : false,
          physicianLevelTag: physician_level,
          department: profession_type?.display_name,
          speciality: specialty_type?.display_name,
          speciality_slug: specialty_slug,
          address:
            auth_user?.address?.city && auth_user?.address?.country
              ? `${auth_user?.address?.city}, ${auth_user?.address?.country}`
              : undefined,
          institute: auth_user?.place_of_works?.[0]?.name,
          description: bio,
          freeAssistance: auth_user?.request_config?.is_free_asst_req ? true : false,
          teleExpertiseFree: auth_user?.physician_hcp_billing_info?.is_te_free ? false : true,
          caseRate: auth_user?.physician_hcp_billing_info?.fee_for_te,
          acceptsSSN: false,
          ratings: 4.9,
          totalRatings: 64,
          languages: languages,
          teleExpertiseFee: auth_user?.physician_hcp_billing_info?.fee_for_te,
          secondOpinionWithVideo: auth_user?.physician_hcp_billing_info?.fee_for_so_wv,
          secondOpinionWithOutVideo: auth_user?.physician_hcp_billing_info?.fee_for_so_wov,
          identityNo: auth_user?.user_no,
          rppsNumber: auth_user?.rpps_number,
          phone: auth_user?.phone,
          email: auth_user?.email
        }
      })
      state.getProfileValidationRequest.data = updated_physician_data
      state.getProfileValidationRequest.pagination = action.payload?.response?.pagination
      state.getProfileValidationRequest.loading = false
    })

    builder.addCase(get_profile_validation_request.rejected, (state) => {
      state.getProfileValidationRequest.loading = false
    })

    // accept reject profile validation request
    builder.addCase(accept_reject_profile_validation_request.pending, (state) => {
      state.acceptRejectProfileValidationRequest.loading = true
    })

    builder.addCase(accept_reject_profile_validation_request.fulfilled, (state) => {
      state.acceptRejectProfileValidationRequest.loading = false
    })

    builder.addCase(accept_reject_profile_validation_request.rejected, (state) => {
      state.acceptRejectProfileValidationRequest.loading = false
    })

    // profile validation
    builder.addCase(profile_validation.pending, (state) => {
      state.profileValidation.loading = true
    })

    builder.addCase(profile_validation.fulfilled, (state) => {
      state.profileValidation.loading = false
    })

    builder.addCase(profile_validation.rejected, (state) => {
      state.profileValidation.loading = false
    })

    // Global Search
    builder.addCase(global_search.pending, (state) => {
      state.globalSearchList.loading = true
    })

    builder.addCase(global_search.fulfilled, (state, action) => {
      state.globalSearchList.loading = false
      state.globalSearchList.data = action.payload?.response
    })

    builder.addCase(global_search.rejected, (state) => {
      state.globalSearchList.loading = false
    })
  }
})

// Action creators are generated for each case reducer function
export const { clearProfileInfo, clearGlobalSearchList } = profileInfoSlice.actions

export default profileInfoSlice.reducer
