import * as lazy from './lazyComponents'
import { AppRoutingConfig } from 'assets/config/AppRoutingConfig'
import { auth_routes } from './authRoutes'
import { physician_routes } from './physicianRoutes'
import { synapp_admin_routes } from './synappAdminRoutes'
import { patient_routes } from './patientRoutes'
import { multi_role_routes } from './multiRoleRoutes'
import { common_routes } from './commonRoutes'
import { author_editor_routes } from './authorEditorRoutes'
import UserDetailsProvider from 'context/UserDetailsProvider'

export const routesConfig = [
  {
    path: AppRoutingConfig.APP_URL_DEFAULT_BASE_REDIRECT,
    element: (
      <UserDetailsProvider>
        <lazy.PageNotFound />
      </UserDetailsProvider>
    )
  },
  {
    path: AppRoutingConfig.VIDEO_CALL,
    element: <lazy.VideoCall />
  },
  ...auth_routes,
  ...physician_routes,
  ...synapp_admin_routes,
  ...patient_routes,
  ...multi_role_routes,
  ...author_editor_routes,
  ...common_routes
]
