import { createAsyncThunk } from '@reduxjs/toolkit'
import { AppRoutingConfig } from 'assets/config/AppRoutingConfig'
import axiosInstance from 'axiosInstance'
import { displayMultipleNotifications } from 'utils/notificationsUtils'

// Define the async thunk using createAsyncThunk
export const login = createAsyncThunk(
  'auth/begin_login',
  async ({ payload, navigate, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/auth/begin-login`, payload)

      if (response.status === 0) {
        showNotification({
          type: 'success',
          message: 'Success!'
        })

        navigate(AppRoutingConfig.APP_URL_VERIFY_YOUR_IDENTITY)
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

export const verify_login = createAsyncThunk(
  'auth/end_login',
  async ({ payload, navigate, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/auth/end-login`, payload)

      if (response.status === 0) {
        navigate(AppRoutingConfig.APP_URL_LOGIN_CONFIRMATION)

        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// Signup flow begin
export const signup = createAsyncThunk(
  'auth/begin_signup',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const dataToBeSent = {
        identifier: payload?.identifier,
        method: payload?.method
      }
      const response = await axiosInstance.post(`v1/auth/user-signup`, dataToBeSent)

      if (response.status === 0) {
        showNotification({
          type: 'success',
          message: 'Success!'
        })
        return { requestPayload: payload, response: response.data, status: response.status }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// Validate OTP sent for signup
export const validate_signup = createAsyncThunk(
  'auth/validate_signup',
  async ({ payload, navigate, showNotification, isPatientSignup = false }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/auth/user-signup-confirmation`, payload)

      if (response.status === 0) {
        showNotification({
          type: 'success',
          message: 'Success!'
        })
        if (!isPatientSignup) {
          navigate(AppRoutingConfig.APP_URL_CHOOSE_YOUR_IDENTITY)
        } else {
          navigate(AppRoutingConfig.APP_URL_PATIENT_ACCOUNT_INFORMATION, {
            state: {
              isPatientSignup
            }
          })
        }
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// Signup with RPPS
export const signup_with_rpps = createAsyncThunk(
  'auth/rpps_signup',
  async ({ payload, navigate, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/auth/rpps-signup`, payload)
      if (response.status === 0) {
        showNotification({
          type: 'success',
          message: 'Success!'
        })
        navigate(AppRoutingConfig.APP_URL_ACCOUNT_INFORMATION)
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// Signup without RPPS
export const end_signup = createAsyncThunk(
  'auth/user_signup_end',
  async ({ payload, navigate, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/auth/user-signup-end`, payload)

      if (response.status === 0) {
        showNotification({
          type: 'success',
          message: 'Success!'
        })
        navigate(AppRoutingConfig.APP_URL_SIGNED_UP_CONFIRMATION)
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// Change password
export const change_password = createAsyncThunk(
  'auth/change_password',
  async ({ payload, showNotification, handleCloseDialog }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/auth/password/change`, payload)

      if (response.status === 0) {
        showNotification({
          type: 'success',
          message: 'Success!'
        })
        handleCloseDialog()
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// Send OTP for phone
export const send_otp_for_phone = createAsyncThunk(
  'auth/send_otp',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/auth/mobile/otp/send`, payload)

      if (response.status === 0) {
        showNotification({
          type: 'success',
          message: 'Success!'
        })
        return { requestPayload: payload, response: response.data, status: response.status }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// Verify OTP for phone
export const verify_otp_for_phone = createAsyncThunk(
  'auth/verify_otp_for_phone',
  async ({ payload, showNotification, handleCloseDialog }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/auth/mobile/otp/verify`, payload)

      if (response.status === 0) {
        showNotification({
          type: 'success',
          message: 'Success!'
        })
        handleCloseDialog()
        return { requestPayload: payload, response: response.data, status: response.status }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// Resend OTP for phone
export const resend_otp_for_phone = createAsyncThunk(
  'auth/verify_otp',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/auth/mobile/otp/resend`, payload)

      if (response.status === 0) {
        showNotification({
          type: 'success',
          message: 'Success!'
        })
        return { requestPayload: payload, response: response.data, status: response.status }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// Send OTP for community phone
export const send_otp_for_community_phone = createAsyncThunk(
  'auth/send_otp_for_community_phone',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/community/change-phone/send-otp`, payload)

      if (response.status === 0) {
        showNotification({
          type: 'success',
          message: 'Success!'
        })
        return { requestPayload: payload, response: response.data, status: response.status }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// Verify OTP for community phone
export const verify_otp_for_community_phone = createAsyncThunk(
  'auth/verify_otp_for_community_phone',
  async ({ payload, showNotification, handleCloseDialog }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/community/change-phone/verify-otp`, payload)

      if (response.status === 0) {
        showNotification({
          type: 'success',
          message: 'Success!'
        })
        handleCloseDialog()
        return { requestPayload: payload, response: response.data, status: response.status }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// Resend OTP for community phone
export const resend_otp_for_community_phone = createAsyncThunk(
  'auth/resend_otp_for_community_phone',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/community/change-phone/resend-otp`, payload)

      if (response.status === 0) {
        showNotification({
          type: 'success',
          message: 'Success!'
        })
        return { requestPayload: payload, response: response.data, status: response.status }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// Send OTP for email
export const send_otp_for_email = createAsyncThunk(
  'auth/send_otp_for_email',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/auth/email/otp/send`, payload)

      if (response.status === 0) {
        showNotification({
          type: 'success',
          message: 'Success!'
        })
        return { requestPayload: payload, response: response.data, status: response.status }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// Resend OTP for email
export const resend_otp_for_email = createAsyncThunk(
  'auth/resend_otp_for_email',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/auth/email/otp/resend`, payload)

      if (response.status === 0) {
        showNotification({
          type: 'success',
          message: 'Success!'
        })
        return { requestPayload: payload, response: response.data, status: response.status }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// Verify OTP for email
export const verify_otp_for_email = createAsyncThunk(
  'auth/verify_otp_for_email',
  async ({ payload, showNotification, handleCloseDialog }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/auth/email/otp/verify`, payload)

      if (response.status === 0) {
        showNotification({
          type: 'success',
          message: 'Success!'
        })
        handleCloseDialog()
        return { requestPayload: payload, response: response.data, status: response.status }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// Send OTP for community email
export const send_otp_for_community_email = createAsyncThunk(
  'auth/send_otp_for_community_email',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/community/change-email/send-otp`, payload)

      if (response.status === 0) {
        showNotification({
          type: 'success',
          message: 'Success!'
        })
        return { requestPayload: payload, response: response.data, status: response.status }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// Verify OTP for community email
export const verify_otp_for_community_email = createAsyncThunk(
  'auth/verify_otp_for_community_email',
  async ({ payload, showNotification, handleCloseDialog }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/community/change-email/verify-otp`, payload)

      if (response.status === 0) {
        showNotification({
          type: 'success',
          message: 'Success!'
        })
        handleCloseDialog()
        return { requestPayload: payload, response: response.data, status: response.status }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// Define the async thunk using createAsyncThunk
export const begin_reset_password = createAsyncThunk(
  'auth/begin_reset_password',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/auth/password-recovery/begin`, payload)

      if (response.status === 0) {
        showNotification({
          type: 'success',
          message: 'Success!'
        })

        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// confirm reset password
export const confirm_reset_password = createAsyncThunk(
  'auth/confirm_reset_password',
  async ({ payload, showNotification, handleCloseDialog }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/auth/password-recovery/end`, payload)

      if (response.status === 0) {
        showNotification({
          type: 'success',
          message: 'Success!'
        })
        handleCloseDialog()

        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// Logout
export const logout = createAsyncThunk(
  'auth/logout',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/auth/logout`, payload)

      if (response.status === 0) {
        showNotification({
          type: 'success',
          message: response?.message
        })

        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// get terms and conditions list
export const get_terms_and_conditions_list = createAsyncThunk(
  'auth/get_terms_and_conditions_list',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/auth/policy/get`, payload)
      if (response.status === 0) {
        return { response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// get privacy policy list
export const get_privacy_policy_list = createAsyncThunk(
  'auth/get_privacy_policy_list',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/auth/policy/get`, payload)
      if (response.status === 0) {
        return { response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)
