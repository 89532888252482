import { createSlice } from '@reduxjs/toolkit'
import { get_physician_home_statistics } from '../services/homeService'

const initialState = {
  homeStatistics: {
    loading: false,
    data: [
      {
        id: 'received_request',
        title: 'AllOpenCases',
        count: 0,
        so_count: 0
      },
      {
        id: 'pending_request',
        title: 'PendingRequests',
        count: 0,
        so_count: 0
      },
      {
        id: 'rejected_request',
        title: 'RejectedCases',
        count: 0,
        so_count: 0
      },
      {
        id: 'answered_request',
        title: 'RequestsAnswered',
        count: 0,
        so_count: 0
      }
    ]
  }
}
export const homeSlice = createSlice({
  name: 'physicianHome',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Home statistics
    builder.addCase(get_physician_home_statistics.pending, (state) => {
      state.homeStatistics.loading = true
    })
    builder.addCase(get_physician_home_statistics.fulfilled, (state, action) => {
      const { te = {}, so = {} } = action.payload.response || {}
      state.homeStatistics.data = state.homeStatistics.data?.map((item) => {
        return {
          ...item,
          count: +te[item.id] || 0,
          so_count: +so[item.id] || 0
        }
      })
      state.homeStatistics.loading = false
    })
    builder.addCase(get_physician_home_statistics.rejected, (state) => {
      state.homeStatistics.loading = false
    })
  }
})

export default homeSlice.reducer
