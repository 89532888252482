import { createSlice } from '@reduxjs/toolkit'
import {
  admin_get_finance_list,
  admin_get_finance_list_community,
  update_finance_status
} from '../services/financeService'

export const financeSlice = createSlice({
  name: 'finance',
  initialState: {
    financeList: {
      loading: false,
      data: [],
      pagination: {}
    },
    financeListCommunity: {
      loading: false,
      data: [],
      pagination: {}
    },
    updateFinanceStatus: {
      loading: false
    }
  },
  reducers: {},
  extraReducers: (builder) => {
    // Get Finance list for admin
    builder.addCase(admin_get_finance_list.pending, (state) => {
      state.financeList.loading = true
    })
    builder.addCase(admin_get_finance_list.fulfilled, (state, action) => {
      state.financeList.loading = false
      state.financeList.data = action.payload?.response?.data
      state.financeList.pagination = action.payload?.response?.pagination
    })
    builder.addCase(admin_get_finance_list.rejected, (state) => {
      state.financeList.loading = false
    })

    // Get Finance list community for admin
    builder.addCase(admin_get_finance_list_community.pending, (state) => {
      state.financeListCommunity.loading = true
    })
    builder.addCase(admin_get_finance_list_community.fulfilled, (state, action) => {
      state.financeListCommunity.loading = false
      state.financeListCommunity.data = action.payload?.response?.data
      state.financeListCommunity.pagination = action.payload?.response?.pagination
    })
    builder.addCase(admin_get_finance_list_community.rejected, (state) => {
      state.financeListCommunity.loading = false
    })

    // Update Finance Status
    builder.addCase(update_finance_status.pending, (state) => {
      state.updateFinanceStatus.loading = true
    })
    builder.addCase(update_finance_status.fulfilled, (state) => {
      state.updateFinanceStatus.loading = false
    })
    builder.addCase(update_finance_status.rejected, (state) => {
      state.updateFinanceStatus.loading = false
    })
  }
})

// export const {  } = financeSlice.actions

export default financeSlice.reducer
