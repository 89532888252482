import { createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from 'axiosInstance'

// Define the async thunk using createAsyncThunk
export const get_cities = createAsyncThunk(
  'app/search_cities',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`v1/profile/search/cities`, {
        params: {
          page: payload?.page || '',
          pageSize: payload?.pageSize || '',
          country: payload?.country || '',
          search: payload?.search || ''
        }
      })

      if (response.status === 0) {
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        if (response?.data?.length > 0) {
          console.log('error', response?.data?.[0]?.message)
        }
      }
    } catch (error) {
      // Handle errors and rejection
      console.error(error)
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

export const search_establishments = createAsyncThunk(
  'app/search_establishments',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/profile/search/establishments`, payload)

      if (response.status === 0) {
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        console.log('error', response?.data?.[0]?.message)
      }
    } catch (error) {
      // Handle errors and rejection
      console.error(error)
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

export const get_profession = createAsyncThunk(
  'app/profession_type',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`v1/profile/profession/type`, {
        params: { page: payload?.page, pageSize: payload?.pageSize, role: payload?.role }
      })

      if (response.status === 0) {
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        console.log('error', response?.data?.[0]?.message)
      }
    } catch (error) {
      // Handle errors and rejection
      console.error(error)
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

export const get_expertise = createAsyncThunk(
  'app/get_expertise',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `v1/profile/expertise/get?page=${payload?.page || ''}&pageSize=${payload?.pageSize || ''}`,
        payload
      )

      if (response.status === 0) {
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        console.log('error', response?.data?.[0]?.message)
      }
    } catch (error) {
      // Handle errors and rejection
      console.error(error)
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

export const get_specialities = createAsyncThunk(
  'app/specialty_type',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/profile/specialty/type`, payload)

      if (response.status === 0) {
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        console.log('error', response?.data?.[0]?.message)
      }
    } catch (error) {
      // Handle errors and rejection
      console.error(error)
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

export const get_sub_specialities = createAsyncThunk(
  'app/sub_specialty_type',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/profile/subspecialties/get`, payload)

      if (response.status === 0) {
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        console.log('error', response?.data?.[0]?.message)
      }
    } catch (error) {
      // Handle errors and rejection
      console.error(error)
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

export const mark_tour_completed = createAsyncThunk(
  'app/mark_tour_completed',
  async ({ payload }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/profile/mark-tour-completed`, payload)

      if (response.status === 0) {
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        console.log('error', response?.data?.[0]?.message)
      }
    } catch (error) {
      // Handle errors and rejection
      console.error(error)
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)
