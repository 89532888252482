import { createSlice } from '@reduxjs/toolkit'
import {
  get_daily_active_users,
  get_patient_demographics,
  get_total_revenue_graph
} from '../services/authorEditorDashboardService'
import { month_labels, week_labels } from 'constants'

const CYAN = 'rgba(3, 139, 181, 1)'
const PURPLE = 'rgba(91, 115, 232, 1)'
const commonChartData = {
  labels: month_labels,
  options: {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
        align: 'start',
        labels: {
          usePointStyle: true,
          pointStyle: 'circle'
        }
      }
    }
    // scales: {
    //   y: {
    //     ticks: {
    //       callback: function (value) {
    //         return '€' + ' ' + value
    //       }
    //     }
    //   }
    // }
  }
}

const initialState = {
  getTotalRevenueGraph: {
    loading: false,
    telexpertiseData: {
      labels: commonChartData.labels,
      datasets: [
        {
          label: 'Tele Expertise',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          borderColor: CYAN,
          backgroundColor: CYAN
        }
      ],
      options: commonChartData.options
    },
    secondOpinionData: {
      labels: commonChartData.labels,
      datasets: [
        {
          label: 'Second Opinion',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          borderColor: PURPLE,
          backgroundColor: PURPLE
        }
      ],
      options: commonChartData.options
    }
  },
  communityDashboard: {
    loading: false,
    data: [
      {
        id: 'telexpertise_total_revenue',
        title: 'TelexpertiseTotalRevenue',
        count: 0
      },
      {
        id: 'second_opinion_total_revenue',
        title: 'SecondOpinionTotalRevenue',
        count: 0
      }
    ]
  },
  getDailyActiveUsers: {
    loading: false,
    data: {
      labels: [],
      datasets: [
        {
          label: '',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          borderColor: PURPLE,
          backgroundColor: PURPLE
        }
      ],
      options: commonChartData.options
    }
  },
  getPatientDemographics: {
    loading: false,
    data: null
  }
}

export const authorEditorDashboardSlice = createSlice({
  name: 'authorEditorDashboard',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // get total revenue graph
    builder.addCase(get_total_revenue_graph.pending, (state) => {
      state.getTotalRevenueGraph.loading = true
    })
    builder.addCase(get_total_revenue_graph.fulfilled, (state, action) => {
      const createMonthlyDataArray = (data) => {
        const monthlyDataArr = Array(12).fill(0)
        data[0]?.month_wise?.forEach(({ month, revenue }) => {
          monthlyDataArr[month - 1] = revenue
        })
        return monthlyDataArr
      }

      const te = action.payload.response?.te
      const so = action.payload.response?.so

      const newTeDataArr = createMonthlyDataArray(te)
      const newSoDataArr = createMonthlyDataArray(so)

      state.getTotalRevenueGraph.telexpertiseData.datasets[0].data = newTeDataArr
      state.getTotalRevenueGraph.secondOpinionData.datasets[0].data = newSoDataArr

      state.communityDashboard.data = state.communityDashboard.data.map((element) => {
        const getRevenue = (id) => {
          switch (id) {
            case 'telexpertise_total_revenue':
              return Number(te?.[0]?.revenue).toFixed(2)

            case 'second_opinion_total_revenue':
              return Number(so?.[0]?.revenue).toFixed(2)

            default:
              break
          }
        }
        return {
          ...element,
          count: getRevenue(element.id) || 0
        }
      })

      state.getTotalRevenueGraph.loading = false
    })
    builder.addCase(get_total_revenue_graph.rejected, (state) => {
      state.getTotalRevenueGraph.loading = false
    })

    // get daily active users
    builder.addCase(get_daily_active_users.pending, (state) => {
      state.getDailyActiveUsers.loading = true
    })
    builder.addCase(get_daily_active_users.fulfilled, (state, action) => {
      state.getDailyActiveUsers.loading = false
      let modified_arr = []
      const filterValue = action?.payload?.requestPayload?.filter?.toLowerCase()
      state.getDailyActiveUsers.data.datasets[0].label =
        filterValue?.[0]?.toUpperCase() + filterValue?.slice(1)

      let weeklyDataArr = Array(week_labels?.length).fill(0)
      let week_labels_arr = week_labels.map((element) => element.toUpperCase())

      switch (action?.payload?.requestPayload?.filter) {
        case 'DAILY':
          modified_arr =
            action?.payload?.response?.map((element) => {
              const hour = element?.hour_group
              return {
                count: element?.count,
                hour: `${hour}:00`
              }
            }) || []

          state.getDailyActiveUsers.data.labels = modified_arr.map((item) => item.hour)
          state.getDailyActiveUsers.data.datasets[0].data = modified_arr.map((item) => item.count)
          break

        case 'WEEKLY':
          state.getDailyActiveUsers.data.labels = [...week_labels]
          modified_arr = action?.payload?.response
          modified_arr?.forEach((element) => {
            const index = week_labels_arr?.findIndex(
              (_element) => _element === element?.day_of_week?.trim()
            )

            if (index !== -1) {
              weeklyDataArr[index] = element?.count
            }
          })
          state.getDailyActiveUsers.data.datasets[0].data = weeklyDataArr
          break

        case 'MONTHLY':
          state.getDailyActiveUsers.data.labels = [...week_labels]
          modified_arr = action?.payload?.response?.map((element) => {
            const month = new Date(element?.month_group).toLocaleString('en-us', {
              month: 'short',
              year: 'numeric'
            })
            return {
              month: month,
              count: element?.count
            }
          })

          state.getDailyActiveUsers.data.labels = modified_arr.map((item) => item.month)
          state.getDailyActiveUsers.data.datasets[0].data = modified_arr.map((item) => item.count)
          break

        default:
          state.getDailyActiveUsers.data.labels = []
          state.getDailyActiveUsers.data.datasets[0].data = [
            ...initialState.getDailyActiveUsers.data.datasets[0].data
          ]
          break
      }
    })
    builder.addCase(get_daily_active_users.rejected, (state) => {
      state.getDailyActiveUsers.loading = false
    })

    // get patient demographics
    builder.addCase(get_patient_demographics.pending, (state) => {
      state.getPatientDemographics.loading = true
    })
    builder.addCase(get_patient_demographics.fulfilled, (state, action) => {
      state.getPatientDemographics.loading = false
      state.getPatientDemographics.data = action?.payload?.response
    })
    builder.addCase(get_patient_demographics.rejected, (state) => {
      state.getPatientDemographics.loading = false
    })
  }
})

export default authorEditorDashboardSlice.reducer
