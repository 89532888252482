import { useTourComponentRefs } from 'context/TourRefsProvider'
import TourTitle from './tour-title'
import { Tour } from 'antd'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { closeTourModel } from '../../../redux/reducer/appReducer'
import { AppService } from '../../../redux/services'
import { useEffect, useState } from 'react'
import './SynappTour.scss'

const SynappTour = () => {
  const { ref1, ref2, ref3, ref4, ref5, ref6 } = useTourComponentRefs()
  const open = useSelector((state) => state.app.tourOpen)
  const dispatch = useDispatch()
  const { getCommunityIncludingMembers } = useSelector((state) => state.common)
  const { t } = useTranslation()

  const initialSteps = [
    {
      title: <TourTitle title={t('productTour.welcome.title')} />,
      description: t('productTour.welcome.description'),
      target: () => ref1.current
    },
    {
      title: <TourTitle title={t('productTour.dashboard.title')} />,
      description: t('productTour.dashboard.description'),
      placement: 'right',
      target: () => ref2.current
    },
    {
      title: <TourTitle title={t('productTour.myRequest.title')} />,
      description: t('productTour.myRequest.description'),
      placement: 'right',
      target: () => ref3.current
    },
    {
      title: <TourTitle title={t('productTour.myLibrary.title')} />,
      description: t('productTour.myLibrary.description'),
      placement: 'right',
      target: () => ref5.current
    },
    {
      title: <TourTitle title={t('productTour.settings.title')} />,
      description: t('productTour.settings.description'),
      placement: 'right',
      target: () => ref6.current
    }
  ]

  const [steps, setSteps] = useState([])

  useEffect(() => {
    setSteps(initialSteps)

    return () => {
      setSteps(initialSteps)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (getCommunityIncludingMembers?.data?.length > 1) {
      setSteps((prevSteps) => [
        ...prevSteps.slice(0, 3),
        {
          title: <TourTitle title={t('productTour.myCommunity.title')} />,
          description: t('productTour.myCommunity.description'),
          placement: 'right',
          target: () => ref4.current
        },
        ...prevSteps.slice(3)
      ])
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCommunityIncludingMembers?.data])

  return (
    <div className="synapp-tour-container">
      {steps.length > 0 && (
        <Tour
          key={steps.length}
          disabledInteraction
          open={open}
          onClose={() => {
            dispatch(closeTourModel())
            dispatch(AppService.mark_tour_completed({ payload: {} }))
          }}
          steps={steps}
          rootClassName="synapp-product-container"
          indicatorsRender={(current, total) => (
            <span>
              {current + 1} of {total}
            </span>
          )}
        />
      )}
    </div>
  )
}

export default SynappTour
