import RequireAuth from 'app/requireAuth'
import { AppRoutingConfig } from 'assets/config/AppRoutingConfig'
import MainLayout from 'components/layouts/main-layout'
import { roles } from '../../constants'
import UserDetailsProvider from 'context/UserDetailsProvider'
import { Navigate } from 'react-router-dom'
import * as lazy from './lazyComponents'
import PrimaryLayout from 'components/layouts/primary-layout'
import ErrorBoundary from 'components/common/error-boundary'
import CaseLayout from 'components/layouts/case-layout'

const CommonWrapper = ({ Layout }) => {
  return (
    <UserDetailsProvider>
      <RequireAuth allowedRoles={[roles.physician, roles.hcp]}>
        <Layout />
      </RequireAuth>
    </UserDetailsProvider>
  )
}

export const physician_routes = [
  {
    path: AppRoutingConfig.APP_URL_ROOT,
    element: <CommonWrapper Layout={MainLayout} />,
    children: [
      {
        index: true,
        element: <Navigate to={AppRoutingConfig.HOME} />
      }
    ]
  },
  {
    path: AppRoutingConfig.SETTINGS,
    element: <CommonWrapper Layout={PrimaryLayout} />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        index: true,
        element: <Navigate to={AppRoutingConfig.PROFILE_INFORMATION} replace />
      },
      {
        path: AppRoutingConfig.ACCOUNT_SETTINGS,
        element: <lazy.AccountSettings />
      },
      {
        path: AppRoutingConfig.REQUEST_MANAGEMENT,
        element: <lazy.RequestsManagement />
      },
      {
        path: AppRoutingConfig.NOTIFICATIONS,
        element: <lazy.Notifications />
      },
      {
        path: AppRoutingConfig.BILLING_INFO,
        element: <lazy.BillingInfo />
      }
    ]
  },
  {
    element: <CommonWrapper Layout={CaseLayout} />,
    children: [
      {
        path: AppRoutingConfig.UPGRADE_PLAN,
        element: <lazy.UpgradeSubscriptionPlan />
      },
      { path: AppRoutingConfig.CREATE_CASE, element: <lazy.CreateCase /> },
      { path: AppRoutingConfig.RESEARCHER_CASE, element: <lazy.ResearcherCase /> }
    ]
  }
]
