import { createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from 'axiosInstance'
import { displayMultipleNotifications } from 'utils/notificationsUtils'

// get so upcoming appointments
export const get_so_upcoming_appointments = createAsyncThunk(
  'appointments/get_so_upcoming_appointments',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/so/upcoming-appointment`, payload)
      if (response.status === 0) {
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)
