import { createSlice } from '@reduxjs/toolkit'
import { get_so_patient_home_statistics } from '../services/patientHomeService'

const initialState = {
  soPatienthomeStatistics: {
    loading: false,
    data: null
  }
}
export const patientHomeSlice = createSlice({
  name: 'patientHome',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // get so patient home statistics
    builder.addCase(get_so_patient_home_statistics.pending, (state) => {
      state.soPatienthomeStatistics.loading = true
    })
    builder.addCase(get_so_patient_home_statistics.fulfilled, (state, action) => {
      state.soPatienthomeStatistics.data = action?.payload?.response
      state.soPatienthomeStatistics.loading = false
    })
    builder.addCase(get_so_patient_home_statistics.rejected, (state) => {
      state.soPatienthomeStatistics.loading = false
    })
  }
})

export default patientHomeSlice.reducer
